import { PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";
import { journeyMenuPropTypes } from "common/JourneyMenu.js";
import { Link } from "react-router-dom";
import "./JourneyChecklist.scss";
import { userPropTypes } from "common/user.js";

export default class JourneyChecklist extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		journeyMenu: journeyMenuPropTypes,
		phase: termPropTypes.isRequired,
		user: userPropTypes,
		moveUserToNextMobilityPhase: PropTypes.func.isRequired
	};

	render() {
		const { t, getUrl, journeyMenu, phase, user, moveUserToNextMobilityPhase } = this.props;
		const submenu = journeyMenu.submenus.find(submenu => submenu.phase.id === phase.id);
		if (!submenu) return null;
		const visitedAllMandatoryNodesOfSubmenu =
			submenu.links.find(link => link.importance === "MANDATORY" && !link.visited) === undefined;
		return (
			<div className="JourneyChecklist" aria-label={t("JourneyChecklist.aria")}>
				{submenu.body && <p>{submenu.body}</p>}
				<ul>
					{submenu.links.map((link, index) => (
						<li key={index}>
							<Link to={getUrl(link.url)} className={link.visited ? "visited" : undefined}>
								<span className="title">{link.title}</span>
								{link.importance && (
									<span className={`importance ${link.importance}`}>
										{t(`JourneyChecklist.importance.${link.importance}`)}
									</span>
								)}
							</Link>
						</li>
					))}
				</ul>
				{submenu.actionTitle && (
					<button
						className="button"
						onClick={moveUserToNextMobilityPhase}
						disabled={!(user && user.mobilityPhase.id === phase.id && visitedAllMandatoryNodesOfSubmenu)}
					>
						{submenu.actionTitle}
					</button>
				)}
			</div>
		);
	}
}
