// Contains rendering functions extracted from ProfilePanel in order to reduce its size.
// Each function accepts the ProfilePanel component instance as an argument in
// order to extract props, state, member functions etc. This is most probably
// and anti-pattern, but it's the easiest way in order to declutter ProfilePanel.

import { Fragment } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { loadReceivingFaculties, loadReceivingFacultyRequirements } from "skeleton/DataAccess.js";
import { renderHostFacultyOverview, getMaxDestinationsAmount } from "skeleton/ProfilePanel.application.js";
import { canSelectDestinations, canSubmitNewApplication } from "common/user.js";
import { renderFragmentWithNewlines as newlines } from "common/utils.js";
import { LIVING_COST_URL } from "config.js";

export const renderDestinationsPanel = parent => {
	const { t, getUrl, url, user, setUserProfileFieldValue } = parent.props;
	const { previewHostFaculty } = parent.state;

	if (!canSelectDestinations(user)) return <Redirect to={getUrl("/profile")} />;

	const userPreferredHostFacultyIds = (user.preferredHostFaculties || []).map(faculty => faculty.id);

	if (previewHostFaculty) {
		return (
			<Fragment>
				<div className="facultyRequirements">
					<div className="faculty">
						<div>
							<h2>
								<Link to={getUrl(previewHostFaculty.organisationAlias)}>
									{previewHostFaculty.organisation}
								</Link>
							</h2>
							<p>
								{previewHostFaculty.city}, {previewHostFaculty.country}, {previewHostFaculty.title}
								{previewHostFaculty.isced && <Fragment>, {previewHostFaculty.isced}</Fragment>}
							</p>
							{userPreferredHostFacultyIds.includes(previewHostFaculty.id) && (
								<p className="small-text saved">{t("saved")}</p>
							)}
						</div>
					</div>
					{renderPossibleStudyLevelsDiscrepancyWarning(t, user, previewHostFaculty)}
					{renderHostFacultyOverview(t, previewHostFaculty)}
				</div>
				<div className="actions">
					<button
						className="button add"
						onClick={() =>
							setUserProfileFieldValue(
								"preferredHostFaculties",
								[...userPreferredHostFacultyIds, previewHostFaculty.id],
								() => parent.setState({ previewHostFaculty: undefined })
							)
						}
						disabled={userPreferredHostFacultyIds.includes(previewHostFaculty.id)}
					>
						{t("ProfilePanel.preferredHostFaculties.confirm")}
					</button>
					<button
						className="button cancel hollow"
						onClick={() => parent.setState({ previewHostFaculty: undefined })}
					>
						{t("cancel")}
					</button>
				</div>
			</Fragment>
		);
	}

	if (!canSubmitNewApplication(user)) {
		return (
			<div className="message pending">
				<p>
					{t.map(
						"ProfilePanel.preferredHostFaculties.cannot_submit_new_application_description",
						{},
						{
							_MY_APPLICATIONS_LINK_: <Link to={getUrl("/applications")}>{t("Applications.title")}</Link>
						}
					)}
				</p>
			</div>
		);
	}

	return (
		<Fragment>
			<nav role="navigation" aria-label={t("ProfilePanel.preferredHostFaculties.title")}>
				<ul>
					<li>
						<NavLink
							to={getUrl("/profile/destinations")}
							role="button"
							className="button small"
							isActive={() => url === "/profile/destinations"}
						>
							{t("ProfilePanel.preferredHostFaculties.find")}
						</NavLink>
					</li>
					<li>
						<NavLink
							to={getUrl("/profile/destinations/preferred")}
							role="button"
							className="button small"
							isActive={() => url === "/profile/destinations/preferred"} // Not really necessary since it's a child of parent URL, but anyway.
						>
							{t("ProfilePanel.preferredHostFaculties.preferred", {
								levels: (user.preferredHostFaculties || []).length
							})}
						</NavLink>
					</li>
				</ul>
			</nav>
			{url === "/profile/destinations" && (
				<Fragment>
					<p className="description">{newlines(t("ProfilePanel.preferredHostFaculties.description"))}</p>
					<div className="button-wrapper">
						<Link to={getUrl(LIVING_COST_URL)} role="button" className="button">
							{t("ProfilePanel.preferredHostFaculties.living_costs_link")}
						</Link>
					</div>
					{renderFindHostFaculties(parent)}
				</Fragment>
			)}
			{url === "/profile/destinations/preferred" && (
				<Fragment>
					<p>
						{t("ProfilePanel.preferredHostFaculties.max", {
							max: getMaxDestinationsAmount(user)
						})}
					</p>
					{renderPreferredHostFaculties(parent)}
				</Fragment>
			)}
		</Fragment>
	);
};

const renderPossibleStudyLevelsDiscrepancyWarning = (t, user, hostFaculty) => {
	// User has no study level.
	if (!user.studyLevel) return null;
	// Faculty has no coop condition info.
	if (!hostFaculty.coopConditionInfo) return null;
	// Faculty has no study levels info.
	if (!hostFaculty.coopConditionInfo.studyLevels) return null;
	// Faculty includes a study level of the user.
	if (hostFaculty.coopConditionInfo.studyLevels.find(studyLevel => studyLevel.id === user.studyLevel.id)) return null;
	// There is discrepancy between user's study level and the faculty's study levels.
	return (
		<div className="message warning">
			<details>
				<summary>{t("ProfilePanel.preferredHostFaculties.studyLevelsDiscrepancyWarning.summary")}</summary>
				<p className="small-text">
					{newlines(t("ProfilePanel.preferredHostFaculties.studyLevelsDiscrepancyWarning.description"))}
				</p>
				<h3>{t("ProfilePanel.preferredHostFaculties.studyLevelsDiscrepancyWarning.title")}</h3>
				<ul className="issues">
					<li>
						{t("ProfilePanel.preferredHostFaculties.studyLevelsDiscrepancyWarning.studyLevel", {
							studyLevel: user.studyLevel.title
						})}
					</li>
				</ul>
			</details>
		</div>
	);
};

const renderFindHostFaculties = parent => {
	const { t, user } = parent.props;
	const { preferredHostFaculty, preferredHostFaculties } = parent.state;

	const userPreferredHostFacultyIds = (user.preferredHostFaculties || []).map(faculty => faculty.id);

	return (
		<div className="editableField">
			<h2>{t("ProfilePanel.preferredHostFaculties.add")}</h2>
			<div>
				<Autosuggest
					suggestions={preferredHostFaculties}
					alwaysRenderSuggestions={true}
					onSuggestionsFetchRequested={({ value, reason }) => {
						// Do not search for faculties if a faculty has been selected.
						// https://github.com/moroshko/react-autosuggest?tab=readme-ov-file#on-suggestions-fetch-requested-prop
						if (reason === "suggestion-selected") return;
						loadReceivingFaculties(user.homeOrganisation.id, user.homeFaculty.title, value).then(values =>
							parent.setState({ preferredHostFaculties: values })
						);
					}}
					onSuggestionsClearRequested={() => parent.setState({ preferredHostFaculties: [] })}
					onSuggestionSelected={(event, { suggestion }) => {
						parent.setState({ preferredHostFaculty: "", previewHostFaculty: suggestion });
						loadReceivingFacultyRequirements(
							suggestion.id,
							suggestion.organisationId,
							suggestion.title
						).then(
							result =>
								parent.state.previewHostFaculty &&
								parent.setState({
									previewHostFaculty: {
										...parent.state.previewHostFaculty,
										...result
									}
								})
						);
					}}
					getSuggestionValue={faculty => faculty.title}
					renderSuggestion={faculty => (
						<Fragment>
							<p className="organisation">{faculty.organisation}</p>
							<p className="small-text location">
								{faculty.city}, {faculty.country}
							</p>
							<p className="small-text faculty-title">
								{faculty.title}
								{faculty.isced && <Fragment>, {faculty.isced}</Fragment>}
							</p>
							{userPreferredHostFacultyIds.includes(faculty.id) && (
								<p className="small-text saved">{t("saved")}</p>
							)}
						</Fragment>
					)}
					inputProps={{
						placeholder: t("ProfilePanel.preferredHostFaculties.prompt"),
						value: preferredHostFaculty,
						onChange: (event, { newValue }) => parent.setState({ preferredHostFaculty: newValue }),
						maxLength: 32
					}}
				/>
			</div>
		</div>
	);
};

const renderPreferredHostFaculties = parent => {
	const { t, getUrl, user, setUserProfileFieldValue } = parent.props;
	const { selectedPreferredHostFacultyIds } = parent.state;

	return (
		<div className="destinations">
			<h2>{t("ProfilePanel.preferredHostFaculties.current")}</h2>
			{user.preferredHostFaculties === undefined ? (
				<p>{t("ProfilePanel.preferredHostFaculties.empty")}</p>
			) : (
				<Fragment>
					<Link
						to={getUrl("/profile/application/period")}
						role="button"
						className={selectedPreferredHostFacultyIds.length ? "button" : "button disabled"}
					>
						{t("ProfilePanel.preferredHostFaculties.apply")}
					</Link>
					<ul>
						{user.preferredHostFaculties.map((faculty, index) => (
							<li
								key={index}
								className={
									selectedPreferredHostFacultyIds.includes(faculty.id) ? "selected" : undefined
								}
							>
								<p>
									<strong>{faculty.organisation}</strong>
									{faculty.city}, {faculty.country}, {faculty.title}
									{faculty.isced && <Fragment>, {faculty.isced}</Fragment>}
								</p>
								{selectedPreferredHostFacultyIds.includes(faculty.id) ? (
									<button
										className="unselect"
										onClick={() => parent.unselectPreferredHostFaculty(faculty.id)}
									>
										<span>{t("unselect")}</span>
									</button>
								) : (
									<button
										className="select"
										onClick={() => parent.selectPreferredHostFaculty(faculty.id)}
									>
										<span>{t("select")}</span>
									</button>
								)}
								<button
									className="delete"
									onClick={() =>
										setUserProfileFieldValue(
											"preferredHostFaculties",
											user.preferredHostFaculties
												.map(faculty => faculty.id)
												.filter(facultyId => facultyId !== faculty.id)
										)
									}
								>
									<span>{t("delete")}</span>
								</button>
								<Link to={getUrl(faculty.organisationAlias)} className="info">
									{t("moreinfo")}
								</Link>
							</li>
						))}
					</ul>
				</Fragment>
			)}
		</div>
	);
};
