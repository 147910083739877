import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import AccommodationMedia from "other/accommodation/AccommodationMedia.js";
import { RentCost } from "other/accommodation/RentCost.js";
import { renderDate, isoDateToTimestamp } from "common/utils.js";

export class AccommodationItem extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		setExitPanelItem: PropTypes.func.isRequired,
		setMapPanelItem: PropTypes.func.isRequired,
		item: PropTypes.object.isRequired
	};

	render() {
		const { t, setExitPanelItem, setMapPanelItem, item } = this.props;

		const renderBedroomType = () => {
			if (item.bedroomCount === 0) return "studio";
			if (item.housingType === "apartment" || item.housingType === "house") return item.bedroomCount + " room(s)";
			if (item.housingType === "room") return "1 room";
			if (item.housingType === "studio") return item.housingType;
			return "";
		};

		return (
			<Fragment>
				<div className="content">
					<h3>
						<span>{item.housingType}</span>
						<button onClick={() => setExitPanelItem(item)}>{item.title}</button>
					</h3>
					<p className="description">{item.description}</p>
					<ul className="meta">
						<li>
							<span className="label">{t("Accommodation.Filters.bedroom_count")}</span>
							<span className="bedroom-type">{renderBedroomType()}</span>
						</li>
						<li>
							<span className="label">{t("Accommodation.Filters.total_size")}</span>
							<span className="size">
								{item.totalSize} m<sup>2</sup>
							</span>
						</li>
						<li>
							<span className="label">{t("Accommodation.availability")}</span>
							<span className="availability">
								<time date={item.availability.startDate}>
									{renderDate(isoDateToTimestamp(item.availability.startDate, t("locale.full")))}
								</time>
								{item.availability.endDate !== "" && (
									<Fragment>
										{" - "}
										<time date={item.availability.endDate}>
											{renderDate(
												isoDateToTimestamp(item.availability.endDate, t("locale.full"))
											)}
										</time>
									</Fragment>
								)}
							</span>
						</li>
						{item.qualityLabels != null && (
							<li>
								<span className="label">{t("Accommodation.QualityLabels.title")}</span>
								<ul className="qualityLabels">
									{item.qualityLabels
										.filter(qualityLabel => qualityLabel.icon)
										.map((qualityLabel, i) => (
											<li key={i} title={qualityLabel.name + " - " + qualityLabel.criteria}>
												<img alt={qualityLabel.name} src={qualityLabel.icon} />
											</li>
										))}
								</ul>
							</li>
						)}
						{item.providerName && (
							<li>
								<span className="label">{t("Accommodation.provider")}</span>
								<span className="provider">{item.providerName}</span>
							</li>
						)}
					</ul>
					{item.media ? <AccommodationMedia media={item.media} /> : <span className="no-image"></span>}
				</div>

				<footer>
					<RentCost t={t} rent={item.rent} currencyCode={item.currencyCode} costs={item.costs} />

					{item.longitude !== undefined && item.latitude !== undefined && (
						<button onClick={() => setMapPanelItem(item)} className="button small map">
							{t("Map.heading")}
						</button>
					)}
				</footer>
			</Fragment>
		);
	}
}
