import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Marker } from "react-leaflet";
import { dealMapPropTypes } from "nodes/Deal.js";
import { eventMapPropTypes } from "nodes/Event.js";
import { organisationMapPropTypes } from "nodes/Organisation.js";
import { storyMapPropTypes } from "nodes/Story.js";
import { tipMapPropTypes } from "nodes/Tip.js";
import L from "leaflet";

const supportedIconTypes = ["deal", "event", "organisation", "service", "story", "tip"];

const createMarkerIcon = (t, node) => {
	const className = "map-icon-" + (supportedIconTypes.includes(node.type) ? node.type : "default");
	const caption = t(supportedIconTypes.includes(node.type) ? "NodeType." + node.type : "Map.icon.default");
	return new L.divIcon({
		className: className,
		iconSize: [36, 36],
		iconAnchor: [18, 18],
		html: "<span>" + caption + "</span>"
	});
};

export default class MapNodeMarker extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.oneOfType([
			dealMapPropTypes,
			eventMapPropTypes,
			organisationMapPropTypes,
			storyMapPropTypes,
			tipMapPropTypes
		]).isRequired,
		moveToNode: PropTypes.func.isRequired
	};

	moveToNode = () => this.props.moveToNode(this.props.node);

	render() {
		const { t, node } = this.props;
		const title = supportedIconTypes.includes(node.type)
			? t("NodeType." + node.type) + ": " + node.title
			: node.title;
		return node.location.map((location, index) => (
			<Marker
				key={`${node.id}-${index}`}
				title={title}
				position={location}
				eventHandlers={{ click: this.moveToNode }}
				icon={createMarkerIcon(t, node)}
			/>
		));
	}
}
