import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Wrapper extends PureComponent {
	static propTypes = {
		className: PropTypes.string.isRequired,
		header: PropTypes.element,
		htmlTag: PropTypes.oneOf(["div", "ul", "section"])
	};

	static defaultProps = {
		className: "Wrapper",
		htmlTag: "div"
	};

	render() {
		const { className, children, header, htmlTag } = this.props;
		// Only render wrapper if there are any actual react children wrapped.
		// This only works for the first level.
		if (
			React.Children.toArray(children).every(
				element =>
					(element.type === Wrapper && React.Children.toArray(element.props.children).length === 0) ||
					(element.type === React.Fragment && React.Children.toArray(element.props.children).length === 0)
			)
		) {
			return null;
		}
		const HtmlTag = htmlTag; // Convert the htmlTag prop to a component.
		return (
			<HtmlTag className={className}>
				{header}
				{children}
			</HtmlTag>
		);
	}
}
