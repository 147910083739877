import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import SlidingPanel from "common/SlidingPanel.js";
import { userPropTypes, loginViaCas, myAidActionUrl } from "common/user.js";
import { renderFragmentWithNewlines as newlines } from "common/utils.js";
import { notifyReactNativeThatLoginModalOpened } from "skeleton/App.js";
import history from "skeleton/history.js";
import "./LoginPanel.scss";
import { RUNS_THROUGH_REACT_NATIVE_APP, PRIVACY_STATEMENT_URL, TERMS_AND_CONDITIONS_URL } from "config.js";

export default class LoginPanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		close: PropTypes.string.isRequired,
		user: userPropTypes
	};

	getInitialState = () => ({
		hasAcceptedPrivacyPolicy: false,
		selectedMethod: undefined // [ undefined, "eulogin", "edugain" ]
	});

	state = this.getInitialState();

	componentDidUpdate(prevProps) {
		if (!prevProps.open && this.props.open && !this.props.user) {
			if (RUNS_THROUGH_REACT_NATIVE_APP) notifyReactNativeThatLoginModalOpened();
		}
	}

	render() {
		const { t, open, close, user } = this.props;
		const { hasAcceptedPrivacyPolicy, selectedMethod } = this.state;
		if (open && user) return <Redirect to="/" />;

		let content = undefined;

		// Step 1.
		if (!selectedMethod) {
			content = (
				<Fragment>
					<ul className="select-method">
						<li>
							<button onClick={() => this.setState({ selectedMethod: "edugain" })}>
								<span>{newlines(t("LoginPanel.edugain.description"))}</span>
								<span className="title">{t("LoginPanel.edugain.title")}</span>
							</button>
						</li>
						<li>
							<button onClick={() => this.setState({ selectedMethod: "eulogin" })}>
								<span>{newlines(t("LoginPanel.eulogin.description"))}</span>
								<span className="title">{t("LoginPanel.eulogin.title")}</span>
							</button>
						</li>
					</ul>
					<div className="help">
						<h2>{t("LoginPanel.help.title")}</h2>
						<p>
							<a href="https://esci-sd.atlassian.net/servicedesk" rel="external noopener noreferrer">
								<span>{t("LoginPanel.help.problem")}</span>
								<span>{t("LoginPanel.help.solution")}</span>
							</a>
						</p>
					</div>
				</Fragment>
			);
		}

		// Step 2.
		if (selectedMethod) {
			content = (
				<Fragment>
					<div className="consent">
						<p>{t("LoginPanel.consent.body")}</p>
						<ul>
							<li>
								<Link to={PRIVACY_STATEMENT_URL} className="button hollow small">
									{t("LoginPanel.consent.privacy_statement")}
								</Link>
							</li>
							<li>
								<Link to={TERMS_AND_CONDITIONS_URL} className="button hollow small">
									{t("LoginPanel.consent.terms_and_conditions")}
								</Link>
							</li>
						</ul>
						<div>
							<label>
								<input
									type="checkbox"
									checked={hasAcceptedPrivacyPolicy}
									onChange={() =>
										this.setState({ hasAcceptedPrivacyPolicy: !hasAcceptedPrivacyPolicy })
									}
								/>
								<span>{t("LoginPanel.consent.action")}</span>
							</label>
						</div>
					</div>
					<div className="login">
						{selectedMethod === "edugain" && (
							<form
								action={myAidActionUrl(
									new URLSearchParams(window.location.search).get("destination") || "/"
								)}
								method="post"
							>
								<button
									name="myaid"
									type="submit"
									className="button"
									disabled={hasAcceptedPrivacyPolicy ? "" : "disabled"}
								>
									{t("LoginPanel.edugain.button")}
								</button>
								<input type="hidden" name="form_id" value="openid_connect_login_form" />
							</form>
						)}
						{selectedMethod === "eulogin" && (
							<button
								className="button"
								onClick={() =>
									loginViaCas(new URLSearchParams(window.location.search).get("destination") || "/")
								}
								disabled={hasAcceptedPrivacyPolicy ? "" : "disabled"}
							>
								{t("LoginPanel.eulogin.button")}
							</button>
						)}
					</div>
				</Fragment>
			);
		}

		return (
			<SlidingPanel
				t={t}
				isVisible={open}
				title={t("LoginPanel.title")}
				extraCssClass="LoginPanel"
				close={() => {
					this.setState(this.getInitialState());
					history.push(close);
				}}
				back={false}
			>
				{content}
			</SlidingPanel>
		);
	}
}
