import { PureComponent } from "react";
import PropTypes from "prop-types";
import Term, { termPropTypes } from "common/Term.js";
import "./Location.scss";

export default class Location extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		country: PropTypes.arrayOf(termPropTypes),
		city: PropTypes.arrayOf(termPropTypes),
		address: PropTypes.arrayOf(PropTypes.string)
	};

	render() {
		const { t, country, city, address } = this.props;
		if (!country && !city) return null;
		return (
			<div className="Location">
				<label>{t("Location.label")}</label>
				<ul>
					<li>
						<address>
							{address && address.map((address, index) => <span key={index}>{address}</span>)}
							{city &&
								city.map((city, index) => (
									<span key={index}>
										<Term term={city} />
									</span>
								))}
							{country &&
								country.map((country, index) => (
									<span key={index}>
										<Term term={country} />
									</span>
								))}
						</address>
					</li>
				</ul>
			</div>
		);
	}
}
