import { PureComponent } from "react";
import PropTypes from "prop-types";
import Ago from "common/Ago.js";
import "./PublishingMeta.scss";

export default class NotificationPublishingMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		notification: PropTypes.object.isRequired
	};

	render() {
		const { t, notification } = this.props;
		return (
			<header className="NotificationPublishingMeta">
				<div className="info">
					{t("NotificationPublishingMeta.label")}
					<Ago t={t} timestamp={notification.created} />
				</div>
			</header>
		);
	}
}
