import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { userPropTypes, userTeaserPropTypes } from "common/user.js";
import Languages from "common/Languages.js";
import { termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "common/utils.js";
import ReportNodeButton from "common/ReportNodeButton.js";

export const faqPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["faq"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	body: PropTypes.string.isRequired,
	summary: PropTypes.string.isRequired,
	miniSummary: PropTypes.string.isRequired,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	tags: PropTypes.arrayOf(termPropTypes),
	faqTypes: PropTypes.arrayOf(termPropTypes).isRequired
});

export const faqStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["faq"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	tags: PropTypes.arrayOf(termPropTypes),
	faqTypes: PropTypes.arrayOf(termPropTypes).isRequired
});

export default class Faq extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: faqPropTypes,
		markNodeElement: PropTypes.element
	};

	render() {
		const { t, getUrl, user, node, markNodeElement } = this.props;
		return (
			<Fragment>
				<Languages
					t={t}
					getUrl={getUrl}
					languages={node.languages}
					language={node.language}
					alias={node.alias}
				/>
				<ClickableDrupalContent content={node.body} />
				<Meta t={t} item={node} />
				{markNodeElement}
				<ReportNodeButton t={t} user={user} node={node} />
			</Fragment>
		);
	}
}

export class FaqTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: faqStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<div className="content">
					<span className="no-image"></span>
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
