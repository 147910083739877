import axios from "axios";
import { MENU_IDS, APPLICATION_VERSION } from "config.js";

export function pingServer() {
	return axios.get("api/ping-server");
}

export function loadUser() {
	return axios.get("api/user").then(result => {
		if (result.data.error) return null;
		return result.data;
	});
}

export function loadLanguages() {
	return axios.get("api/languages").then(result => result.data);
}

export function loadMenus() {
	return axios.get("api/menus", { params: { ids: MENU_IDS } }).then(result => result.data);
}

export function loadTerms() {
	return axios.get("api/terms").then(result => result.data);
}

export function loadJourneyMenu() {
	return axios
		.get("api/journey-menu")
		.then(result => result.data)
		.catch(error => null);
}

export function loadStream(phaseId, view, cityId, contentTypes, tagIds, search) {
	return axios
		.get("api/stream", {
			params: { phase: phaseId, view: view, city: cityId, cts: contentTypes, tags: tagIds, search: search }
		})
		.then(result => result.data);
}

export function loadNextStreamNodes(stream) {
	const nextPage = stream.page + 1;
	const idsToLoad = stream.ids.slice(
		nextPage * stream.nodesPerPage,
		nextPage * stream.nodesPerPage + stream.nodesPerPage
	);
	return axios.get("api/stream/nodes", { params: { ids: idsToLoad } }).then(result => ({
		...stream,
		nodes: stream.nodes.concat(result.data),
		page: nextPage
	}));
}

export function loadNode(url, search) {
	return axios
		.get(`api/node?url=${encodeURIComponent(url)}${search ? "&search=" + encodeURIComponent(search) : ""}`)
		.then(result => {
			if (typeof result.data !== "object") {
				throw new Error("Response not an object");
			}
			return result.data;
		});
}

export function updateUserLanguage(language) {
	return axios.post("api/user/language", { language: language });
}

export function moveUserToNextMobilityPhase(currentMobilityPhase) {
	return axios.post("api/user/next-mobility-phase", { currentMobilityPhase: currentMobilityPhase });
}

/**
 * Nodes can now be manually marked and unmarked visited (done).
 */
export function markNodeVisited(url) {
	return axios.post("api/user/visit-url", { url: url });
}

/**
 * Nodes can now be manually marked and unmarked visited (done).
 */
export function unmarkNodeVisited(url) {
	return axios.post("api/user/unvisit-url", { url: url });
}

export function readNotification(notificationId) {
	return axios.post("api/user/read-notification", { id: notificationId });
}

export function unreadNotification(notificationId) {
	return axios.post("api/user/unread-notification", { id: notificationId });
}

export function loadNotification(notificationId) {
	return axios.get("api/notification", { params: { id: notificationId } }).then(result => result.data);
}

export function likeNode(nodeId) {
	return axios.post("api/user/like-node", { id: nodeId });
}

export function unlikeNode(nodeId) {
	return axios.post("api/user/unlike-node", { id: nodeId });
}

export function uploadUserAvatar(file) {
	const formData = new FormData();
	formData.append("picture", file, file.name);
	return axios.post("api/user/upload-picture", formData, { timeout: 0 });
}

export function removeUserAvatar() {
	return axios.post("api/user/remove-picture");
}

export function uploadRealPicture(file) {
	const formData = new FormData();
	formData.append("picture", file, file.name);
	return axios.post("api/user/upload-real-picture", formData, { timeout: 0 });
}

export function loadOrganisations(search) {
	return axios.get("api/organisations", { params: { search: search } }).then(result => result.data);
}

export function loadStudyFields(search) {
	return axios.get("api/study-fields", { params: { search: search } }).then(result => result.data);
}

export const updateUserProgramme = programme => axios.post("api/user/update-programme", { programme: programme });
export const removeUserProgramme = () => axios.post("api/user/remove-programme");

export const updateUserHomeOrganisation = organisation =>
	axios.post("api/user/update-organisation", { organisation, field: "home" });
export const removeUserHomeOrganisation = () => axios.post("api/user/remove-organisation", { field: "home" });

export const updateUserHostOrganisation = organisation =>
	axios.post("api/user/update-organisation", { organisation, field: "host" });
export const removeUserHostOrganisation = () => axios.post("api/user/remove-organisation", { field: "host" });

export const updateUserSubscribedNotificationCategories = subscribedNotificationCategoryIds =>
	axios.post("api/user/update-subscribed-notification-categories", {
		subscribedNotificationCategoryIds: subscribedNotificationCategoryIds
	});

export const updateUserHomeFaculty = value => axios.post("api/user/update-home-faculty", { homeFaculty: value });
export const removeUserHomeFaculty = () => axios.post("api/user/remove-home-faculty");

export const updateUserEctsCreditsCompleted = value =>
	axios.post("api/user/update-ects-credits-completed", { ectsCreditsCompleted: value });
export const removeUserEctsCreditsCompleted = () => axios.post("api/user/remove-ects-credits-completed");

export const updateUserStudyLevel = value => axios.post("api/user/update-study-level", { studyLevel: value });
export const removeUserStudyLevel = () => axios.post("api/user/remove-study-level");

export const updateUserStudyField = value => axios.post("api/user/update-study-field", { studyField: value });
export const removeUserStudyField = () => axios.post("api/user/remove-study-field");

export const updateUserLanguageSkills = value =>
	axios.post("api/user/update-language-skills", { languageSkills: value });

export const updateUserPreferredHostFaculties = value =>
	axios.post("api/user/update-preferred-host-faculties", { preferredHostFaculties: value });

export function loadCities(search) {
	return axios.get("api/cities", { params: { search: search } }).then(result => result.data);
}

export const loadReceivingFaculties = (sendingOrganisationId, sendingFacultyTitle, search) =>
	axios
		.get("api/host-faculties", { params: { sendingOrganisationId, sendingFacultyTitle, search } })
		.then(result => result.data);

export const loadReceivingFacultyRequirements = (
	receivingFacultyId,
	receivingOrganisationId,
	receivingFacultyTitle,
	search
) =>
	axios
		.get("api/host-faculty-requirements", {
			params: { receivingFacultyId, receivingOrganisationId, receivingFacultyTitle, search }
		})
		.then(result => result.data);

export function reportNode(nodeId) {
	return axios.post("api/report/node", { id: nodeId });
}

export function postPushSubscription(subscription) {
	return axios.post("api/push-subscription", subscription);
}

export const submitForm = (formId, formData) =>
	axios.post(`api/form?formId=${encodeURIComponent(formId)}`, formData, {
		timeout: 0 // Do not timeout because the user may be uploading files.
	});

export const saveApplication = formData =>
	axios.post("api/application/save", formData, {
		timeout: 0 // Do not timeout because the user may be uploading files.
	});

export const saveAndSubmitApplication = formData =>
	axios.post("api/application/save-and-submit", formData, {
		timeout: 0 // Do not timeout because the user may be uploading files.
	});

export const deleteDraftApplication = () => axios.post("api/application/delete-draft");

export const updateApplication = (id, status) => axios.post("api/application/update", { id, status });

export const loadSkillsAssessment = () => axios.get("api/skills-assessment").then(result => result.data);

export const createSkillsAssessmentMobility = formData => axios.post("api/skills-assessment/mobility/add", formData);

export const editSkillsAssessmentMobility = (mobilityId, formData) =>
	axios.post(`api/skills-assessment/mobility/edit?mobilityId=${encodeURIComponent(mobilityId)}`, formData);

export const deleteSkillsAssessmentMobility = mobilityId =>
	axios.post(`api/skills-assessment/mobility/delete?mobilityId=${encodeURIComponent(mobilityId)}`);

export const submitSkillsAssessmentMobility = (mobilityId, formData) =>
	axios.post(`api/skills-assessment/mobility/submit?mobilityId=${encodeURIComponent(mobilityId)}`, formData);

export const isOnboardingCompleted = () => localStorage.getItem("onboarding-completed") === "true";

export const setOnboardingCompleted = () => localStorage.setItem("onboarding-completed", "true");

export function deleteNode(nodeId) {
	return axios.post("api/delete/node", { id: nodeId });
}

/**
 * Generic network error handling.
 */
export function handleError(
	t,
	error,
	errorHandler401 = () => window.alert(t("Loading.error401.title") + " - " + t("Loading.error401.content")),
	errorHandler404 = () => window.alert(t("Loading.error404.title") + " - " + t("Loading.error404.content")),
	errorHandler5xx = () => window.alert(t("Loading.error5xx.title") + " - " + t("Loading.error5xx.content")),
	errorHandlerNetwork = () =>
		window.alert(t("Loading.errorNetwork.title") + " - " + t("Loading.errorNetwork.content"))
) {
	console.log(error);
	if (error.response && error.response.status === 401) {
		errorHandler401();
	} else if (error.response && error.response.status === 404) {
		errorHandler404();
	} else if (error.response && error.response.status >= 500) {
		errorHandler5xx();
	} else if (
		error.code === "ECONNABORTED" ||
		!window.navigator.onLine ||
		error.toString().includes("Network Error")
	) {
		errorHandlerNetwork();
	} else {
		window.alert(t("api.error"));
	}
}

export const trackError = (message, script, line, column, error) =>
	axios
		.post("api/error", {
			message,
			script,
			line,
			column,
			error,
			agent: window.navigator.userAgent,
			url: window.location.href,
			version: APPLICATION_VERSION
		})
		.then(() =>
			window.alert("An application error has occured. The administrators have been notified. Please try again.")
		);
