import { PureComponent } from "react";
import PropTypes from "prop-types";
import SlidingPanel from "common/SlidingPanel.js";
import "./Loading.scss";

export const statusPropTypes = PropTypes.oneOf(["loading", "error-404", "error-401", "error-5xx", "error-network"]);

export default class Loading extends PureComponent {
	static propTypes = {
		t: PropTypes.func,
		status: statusPropTypes,
		retry: PropTypes.func,
		close: PropTypes.func,
		instant: PropTypes.bool // Useful in case we need the loading spinner to appear immediately (because we know the API will be always slow).
	};

	static defaultProps = {
		status: "loading"
	};

	render() {
		const { t, status, retry, close, instant } = this.props;

		if (status === "error-404") {
			return (
				<SlidingPanel
					t={t}
					isVisible={true}
					title={t("Loading.error404.title")}
					extraCssClass="error-404"
					close={close}
					back={false}
				>
					<h2>{t("Loading.error404.header")}</h2>
					<p>{t("Loading.error404.content")}</p>
				</SlidingPanel>
			);
		}
		if (status === "error-401") {
			return (
				<SlidingPanel
					t={t}
					isVisible={true}
					title={t("Loading.error401.title")}
					extraCssClass="error-401"
					close={close}
					back={false}
				>
					<h2>{t("Loading.error401.header")}</h2>
					<p>{t("Loading.error401.content")}</p>
				</SlidingPanel>
			);
		}
		if (status === "error-5xx") {
			return (
				<SlidingPanel
					t={t}
					isVisible={true}
					title={t("Loading.error5xx.title")}
					extraCssClass="error-5xx"
					close={close}
					back={false}
				>
					<h2>{t("Loading.error5xx.header")}</h2>
					<p>{t("Loading.error5xx.content")}</p>
					{retry && (
						<button onClick={retry} className="button retry">
							{t("retry")}
						</button>
					)}
				</SlidingPanel>
			);
		}
		if (status === "error-network") {
			return (
				<SlidingPanel
					t={t}
					isVisible={true}
					title={t("Loading.errorNetwork.title")}
					extraCssClass="error-Network"
					close={close}
					back={false}
				>
					<h2>{t("Loading.errorNetwork.header")}</h2>
					<p>{t("Loading.errorNetwork.content")}</p>
					{retry && (
						<button onClick={retry} className="button retry">
							{t("retry")}
						</button>
					)}
				</SlidingPanel>
			);
		}

		return (
			<div className={"Loading" + (status ? " " + status : "") + (instant ? " instant" : "")}>
				{status === "loading" && <p>{t ? t("Loading.loading") : "Loading..."}</p>}
				{close && (
					<button onClick={close} className="button secondary small close">
						{t("close")}
					</button>
				)}
			</div>
		);
	}
}
