import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { userPropTypes, userTeaserPropTypes } from "common/user.js";
import Image, { imagePropTypes } from "common/Image.js";
import { termPropTypes } from "common/Term.js";
import { latLonPropTypes } from "common/utils.js";
import Meta from "common/Meta.js";
import NodeTopMeta from "common/NodeTopMeta.js";
import NodePublishingMeta from "common/NodePublishingMeta.js";
import { websitePropTypes } from "common/Website.js";
import OrganisationReference, { organisationReferencePropTypes } from "common/OrganisationReference.js";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "common/utils.js";
import ReportNodeButton from "common/ReportNodeButton.js";

export const eventPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["event"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	body: PropTypes.string.isRequired,
	summary: PropTypes.string.isRequired,
	miniSummary: PropTypes.string.isRequired,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	address: PropTypes.arrayOf(PropTypes.string),
	location: PropTypes.arrayOf(latLonPropTypes),
	tags: PropTypes.arrayOf(termPropTypes),
	startDate: PropTypes.number.isRequired,
	endDate: PropTypes.number.isRequired,
	website: websitePropTypes,
	organisations: organisationReferencePropTypes,
	studentOrganisation: PropTypes.string
});

export const eventStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["event"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	tags: PropTypes.arrayOf(termPropTypes),
	studentOrganisation: PropTypes.string
});

export const eventMapPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["event"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	location: PropTypes.arrayOf(latLonPropTypes)
});

export default class Event extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: eventPropTypes,
		markNodeElement: PropTypes.element
	};

	render() {
		const { t, getUrl, user, node, markNodeElement } = this.props;
		return (
			<Fragment>
				<Image image={node.image} type="full" />
				<NodeTopMeta t={t} getUrl={getUrl} node={node} />
				<ClickableDrupalContent content={node.body} />
				<Meta t={t} item={node} />
				<OrganisationReference t={t} getUrl={getUrl} organisations={node.organisations} type="full" />
				{markNodeElement}
				<ReportNodeButton t={t} user={user} node={node} />
			</Fragment>
		);
	}
}

export class EventTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: eventStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<NodePublishingMeta t={t} node={node} />
				<div className="content">
					<Image image={node.image} type="teaser" />
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
