import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { programmePropTypes } from "common/Term.js";
import { getLinkToLoginPage } from "common/user.js";
import { ABOUT_URL } from "config.js";
import { Link } from "react-router-dom";
import { applyExpandableHtmlLogic } from "common/utils.js";

const AMOUNT_OF_SLIDES = 6;

export default class Onboarding extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		programmes: PropTypes.arrayOf(programmePropTypes),
		setOnboardingCompleted: PropTypes.func.isRequired
	};

	state = {
		activeSlide: 0
	};

	componentDidMount() {
		this.enhanceExpandablesContent();
	}

	componentDidUpdate() {
		this.enhanceExpandablesContent();
	}

	moveToPrevious = () => this.setState({ activeSlide: this.state.activeSlide - 1 });

	moveToNext = () => this.setState({ activeSlide: this.state.activeSlide + 1 });

	xStart = null;
	yStart = null;

	onFingerStart = (x, y) => {
		this.xStart = x;
		this.yStart = y;
		this.setState({ dragging: true });
	};

	onFingerMove = (x, y) => {
		if (this.xStart === null || this.yStart === null) return;

		const MOVE_THRESHOLD = 10;

		const xDiff = x - this.xStart;
		const yDiff = y - this.yStart;

		if (Math.abs(xDiff) < Math.abs(yDiff)) return; // Not significant X axis move.

		if (xDiff < -MOVE_THRESHOLD && this.state.activeSlide < AMOUNT_OF_SLIDES - 1) {
			this.moveToNext();
			this.onFingerEnd();
			return;
		}
		if (xDiff > MOVE_THRESHOLD && this.state.activeSlide > 0) {
			this.moveToPrevious();
			this.onFingerEnd();
			return;
		}
	};

	onFingerEnd = () => {
		this.xStart = null;
		this.yStart = null;
	};

	onTouchStart = e => this.onFingerStart(e.touches[0].clientX, e.touches[0].clientY);
	onTouchMove = e => this.onFingerMove(e.touches[0].clientX, e.touches[0].clientY);
	onTouchEnd = () => this.onFingerEnd();
	onMouseDown = e => this.onFingerStart(e.clientX, e.clientY);
	onMouseMove = e => this.onFingerMove(e.clientX, e.clientY);
	onMouseUp = () => this.onFingerEnd();

	/**
	 * Mutates the rendered HTML content and enhances all expandables, adding
	 * appropriate classes and aria attributes.
	 */
	enhanceExpandablesContent = () => {
		if (this.discoverDomNode === undefined) return;
		this.discoverDomNode
			.querySelectorAll(".expandable")
			.forEach(expandable => applyExpandableHtmlLogic(expandable));
	};

	render() {
		const { t, getUrl, programmes, setOnboardingCompleted } = this.props;
		const { activeSlide } = this.state;

		// First AMOUNT_OF_SLIDES slides.
		if (activeSlide < AMOUNT_OF_SLIDES) {
			return (
				<div className="slider">
					<div
						className={`slide slide-${activeSlide}`}
						onTouchStart={this.onTouchStart}
						onTouchMove={this.onTouchMove}
						onTouchEnd={this.onTouchEnd}
						onMouseDown={this.onMouseDown}
						onMouseMove={this.onMouseMove}
						onMouseUp={this.onMouseUp}
					>
						<div>
							<h2>{t(`Onboarding.slide${activeSlide}.title`)}</h2>
							<p>{t(`Onboarding.slide${activeSlide}.description`)}</p>
						</div>
					</div>
					<nav role="navigation" aria-label={t("Onboarding.aria")}>
						<ol>
							{[...Array(AMOUNT_OF_SLIDES)].map((_, index) => (
								<li key={index}>
									<button
										onClick={() => this.setState({ activeSlide: index })}
										className={index === activeSlide ? "current" : ""}
									>
										{t(`Onboarding.slide${index}.title`)}
									</button>
								</li>
							))}
						</ol>
					</nav>
					<footer>
						<button className="button next" onClick={this.moveToNext}>
							{t("next")}
						</button>
					</footer>
				</div>
			);
		}

		// Discover section (an extra slide without navigation and a slightly different look).
		if (activeSlide === AMOUNT_OF_SLIDES) {
			return (
				<div className="discover" ref={node => (this.discoverDomNode = node)}>
					<header>
						<h2>{t("Onboarding.discover.title")}</h2>
					</header>
					<section>
						<p>{t("Onboarding.discover.description")}</p>
						<p className="login">
							<Link className="button" to={getLinkToLoginPage()} onClick={setOnboardingCompleted}>
								{t("Onboarding.discover.login")}
							</Link>
						</p>
						{programmes && (
							<ul className="programmes">
								{programmes.map(programme => (
									<li key={programme.id}>
										<div className="expandable">
											<button>{programme.title}</button>
											{programme.features.length > 0 && (
												<div className="expandable-content">
													<p>
														{t("Onboarding.discover.intro", {
															programme: programme.title
														})}
													</p>
													<ul>
														{programme.features.map((feature, index) => (
															<li key={index}>{feature}</li>
														))}
													</ul>
												</div>
											)}
										</div>
									</li>
								))}
							</ul>
						)}
						<p className="extra">
							{t.map(
								"Onboarding.discover.extra",
								{},
								{
									_ABOUT_: (
										<Link to={getUrl(ABOUT_URL)} onClick={setOnboardingCompleted}>
											{t("about")}
										</Link>
									)
								}
							)}
						</p>
					</section>
					<footer>
						<button className="button secondary small" onClick={setOnboardingCompleted}>
							{t("Onboarding.discover.start")}
						</button>
					</footer>
				</div>
			);
		}
	}
}
