import { PureComponent } from "react";
import PropTypes from "prop-types";
import DateRange from "common/DateRange.js";
import Website from "common/Website.js";
import Location from "common/Location.js";
import OrganisationReference from "common/OrganisationReference.js";
import "./NodeTopMeta.scss";

export default class NodeTopMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, getUrl, node } = this.props;
		if (!node.organisations && !node.startDate && !node.website && !node.country && !node.city) return null;
		return (
			<div className="NodeTopMeta">
				<h2>{t("NodeTopMeta.heading", { type: node.type })}</h2>
				<OrganisationReference t={t} getUrl={getUrl} organisations={node.organisations} type="links" />
				<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
				<Location t={t} country={node.country} city={node.city} address={node.address} />
				<Website t={t} website={node.website} />
			</div>
		);
	}
}
