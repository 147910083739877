import { PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";
import "./JourneyNavigator.scss";
import { Link, NavLink } from "react-router-dom";
import { userPropTypes, getLinkToLoginPage } from "common/user.js";
import { journeyMenuPropTypes } from "common/JourneyMenu.js";
import history from "skeleton/history.js";
import { decodeQuery } from "common/utils.js";

export default class JourneyNavigator extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		search: PropTypes.string,
		user: userPropTypes,
		journeyMenu: journeyMenuPropTypes,
		activePhase: termPropTypes,
		view: PropTypes.oneOf(["stream", "map", "liked", "liked-map", "my-content", "my-content-map", "search"])
			.isRequired,
		filtersEffective: PropTypes.bool.isRequired,
		hiddenTimeline: PropTypes.bool.isRequired,
		title: PropTypes.element
	};

	state = {
		search: decodeQuery(this.props.search).q || ""
	};

	onSearchFormSubmit = e => {
		e.preventDefault();
		history.replace(this.props.getUrl(undefined, { q: this.state.search }));
	};

	renderTimeline = () => {
		const { t, getUrl, user, journeyMenu, activePhase, hiddenTimeline } = this.props;
		if (!journeyMenu) return null;
		const userPhase = user && user.mobilityPhase ? user.mobilityPhase : undefined;
		const completedJourneyMenuPhasesIds = [];
		for (let i = 0; i < journeyMenu.submenus.length; i++) {
			if (!userPhase || journeyMenu.submenus[i].phase.id === userPhase.id) break;
			completedJourneyMenuPhasesIds.push(journeyMenu.submenus[i].phase.id);
		}
		return (
			<div className={"timeline" + (hiddenTimeline ? " hidden" : "")}>
				<nav role="navigation" aria-label={t("JourneyNavigator.aria")}>
					<ol>
						{journeyMenu.submenus.map(submenu => (
							<li
								key={submenu.phase.id}
								className={`${activePhase && activePhase.id === submenu.phase.id ? "active" : ""}`}
							>
								<Link to={getUrl("/", { phase: submenu.phase.id })}>{submenu.title}</Link>
								{completedJourneyMenuPhasesIds.includes(submenu.phase.id) && (
									<span className="completed">{t("JourneyNavigator.completed")}</span>
								)}
							</li>
						))}
						{!user && (
							<li className="login">
								<Link to={getLinkToLoginPage()}>{t("JourneyNavigator.login")}</Link>
							</li>
						)}
					</ol>
				</nav>
			</div>
		);
	};

	renderTitle = () => {
		const { title } = this.props;
		if (!title) return null;
		return (
			<div className="title">
				<h1>{title}</h1>
			</div>
		);
	};

	renderControls = () => {
		const { t, getUrl, activePhase, view, filtersEffective } = this.props;
		return (
			<div className="stream-controls">
				{["stream", "map"].includes(view) && (
					<NavLink
						to={getUrl("/checklist", { phase: activePhase.id })}
						className="button checklist"
						role="button"
						aria-haspopup="true"
					>
						{t("JourneyNavigator.checklist")}
					</NavLink>
				)}
				{view === "stream" && (
					<NavLink to={getUrl("/", { view: "map" })} className="button map">
						{t("JourneyNavigator.map")}
					</NavLink>
				)}
				{view === "map" && (
					<NavLink to={getUrl("/", { view: undefined })} className="button stream">
						{t("JourneyNavigator.stream")}
					</NavLink>
				)}
				{view === "liked" && (
					<NavLink to={getUrl("/", { view: "liked-map" })} className="button map">
						{t("JourneyNavigator.map")}
					</NavLink>
				)}
				{view === "liked-map" && (
					<NavLink to={getUrl("/", { view: "liked" })} className="button stream">
						{t("JourneyNavigator.stream")}
					</NavLink>
				)}
				{view === "my-content" && (
					<NavLink to={getUrl("/", { view: "my-content-map" })} className="button map">
						{t("JourneyNavigator.map")}
					</NavLink>
				)}
				{view === "my-content-map" && (
					<NavLink to={getUrl("/", { view: "my-content" })} className="button stream">
						{t("JourneyNavigator.stream")}
					</NavLink>
				)}
				{view === "search" && (
					<form onSubmit={this.onSearchFormSubmit}>
						<input
							type="text"
							name="q"
							value={this.state.search}
							onChange={e => this.setState({ search: e.target.value })}
							placeholder={t("AppHeader.search")}
						/>
						<button className="button" type="submit">
							{t("apply")}
						</button>
					</form>
				)}
				<NavLink to={getUrl("/filters")} className="button filters" aria-live="polite">
					{t("JourneyNavigator.filters")}
					{filtersEffective ? (
						<span className="effective">{t("JourneyNavigator.filters_effective")}</span>
					) : (
						<span>{t("JourneyNavigator.filters_not_effective")}</span>
					)}
				</NavLink>
			</div>
		);
	};

	render() {
		const { user } = this.props;
		return (
			<div className={"JourneyNavigator" + (user ? "" : " anonymous")}>
				{this.renderTimeline()}
				<div className="app-subheader">
					{this.renderTitle()}
					{this.renderControls()}
				</div>
			</div>
		);
	}
}
