import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import history from "skeleton/history.js";
import Loading, { statusPropTypes } from "common/Loading.js";
import { handleError, loadNode as loadNodeFromServer } from "skeleton/DataAccess.js";
import NodePanel from "nodes/NodePanel.js";
import { nodePropTypes } from "nodes/PropTypes.js";
import { userPropTypes } from "common/user.js";

export default class NodeLoader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		url: PropTypes.string.isRequired,
		search: PropTypes.string,
		shouldLoadNode: PropTypes.bool.isRequired,
		onNodeVisitedChange: PropTypes.func.isRequired,
		onNodeLikesChange: PropTypes.func.isRequired,
		setParentState: PropTypes.func.isRequired,
		node: nodePropTypes,
		nodeLoadingStatus: statusPropTypes,
		onNodeDeleted: PropTypes.func.isRequired,
		close: PropTypes.string.isRequired
	};

	componentDidMount() {
		// ssr.php may have already provided the data we need.
		if (this.props.node) {
			return;
		}
		this.loadNode();
	}

	/**
	 * Loads the node from the server.
	 */
	loadNode = () => {
		const { t, url, search, shouldLoadNode, setParentState } = this.props;

		// ESA-210 Redirect trailing "/" to non "/" URL.
		// ".+" in regex makes sure to exclude frontpage which is "/".
		if (url.match(/.+\/$/)) {
			console.log("Trailing slash found in URL. Moving to non slash URL.");
			history.replace(url.substring(0, url.length - 1));
			return;
		}

		if (!shouldLoadNode) {
			setParentState({ node: undefined, nodeLoadingStatus: undefined });
			return;
		}

		console.log("Loading node data from server.");
		setParentState({ nodeLoadingStatus: "loading" });

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		loadNodeFromServer(url, search)
			.then(result => {
				if (requestId !== this.lastRequestId) return; // Ignore stale response.
				console.log(`XHR loaded for url: ${url}${search ? `, search: ${search || ""}` : ``}`);
				setParentState({ node: result, nodeLoadingStatus: undefined });
			})
			.catch(error =>
				handleError(
					t,
					error,
					undefined, // 401
					() => setParentState({ node: undefined, nodeLoadingStatus: "error-404" }),
					() => setParentState({ node: undefined, nodeLoadingStatus: "error-5xx" }),
					() => setParentState({ node: undefined, nodeLoadingStatus: "error-network" })
				)
			);
	};

	closeLoading = () => {
		this.props.setParentState({ nodeLoadingStatus: undefined });
		history.replace(this.props.close);
	};

	componentDidUpdate(prevProps) {
		if (prevProps.url !== this.props.url || prevProps.search !== this.props.search) {
			console.log(
				"Page URL changed from " +
					prevProps.url +
					(prevProps.search ? "?" + prevProps.search : "") +
					" to " +
					this.props.url +
					(this.props.search ? "?" + this.props.search : "")
			);
			this.loadNode();
			return;
		}

		if (
			this.props.node &&
			prevProps.node !== this.props.node &&
			window.location.hash &&
			window.location.hash.length > 1
		) {
			console.log("Rendered new content and URL contains hash. Refresh hash.");
			window.location = window.location.pathname + window.location.search + window.location.hash;
		}
	}

	onNodeLikesChange = nodeSubsetWithUpdatedLikes => {
		this.props.setParentState({ node: { ...this.props.node, ...nodeSubsetWithUpdatedLikes } });
		this.props.onNodeLikesChange(nodeSubsetWithUpdatedLikes);
	};

	onNodeVisitedChange = nodeSubsetWithUpdatedVisited => {
		this.props.setParentState({ node: { ...this.props.node, ...nodeSubsetWithUpdatedVisited } });
		this.props.onNodeVisitedChange();
	};

	render() {
		const { t, getUrl, user, node, nodeLoadingStatus, onNodeDeleted, close } = this.props;

		return (
			<Fragment>
				{nodeLoadingStatus && (
					<Loading t={t} status={nodeLoadingStatus} retry={this.loadNode} close={this.closeLoading} />
				)}
				<NodePanel
					t={t}
					getUrl={getUrl}
					user={user}
					open={node !== undefined}
					node={node}
					close={close}
					onNodeLikesChange={this.onNodeLikesChange}
					onNodeVisitedChange={this.onNodeVisitedChange}
					onNodeDeleted={onNodeDeleted}
				/>
			</Fragment>
		);
	}
}
