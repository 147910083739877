import { PureComponent } from "react";
import PropTypes from "prop-types";
import { renderNodeUrl } from "common/utils.js";
import { userPropTypes } from "common/user.js";
import { MapContainer, TileLayer } from "react-leaflet";
import {
	MAP_PREVIEW_MIN_ZOOM,
	MAP_PREVIEW_DEFAULT_ZOOM,
	MAP_PREVIEW_MAX_ZOOM,
	MAP_DEFAULT_CENTER,
	MAP_OSM_TILES_SERVER
} from "config.js";
import "./Map.scss";
import "leaflet/dist/leaflet.css";
import MapNodeMarker from "common/MapNodeMarker.js";
import history from "skeleton/history.js";
import { mapNodePropTypes } from "nodes/PropTypes.js";

const UNLIMITED_MAP_PREVIEW_MIN_ZOOM = 3;

export default class Map extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		nodes: PropTypes.arrayOf(mapNodePropTypes).isRequired,
		limitMinZoom: PropTypes.bool.isRequired
	};

	moveToNode = node => history.push(this.props.getUrl(renderNodeUrl(node, this.props.user)));

	render() {
		const { t, nodes, limitMinZoom } = this.props;

		// Calculate bounds based on given nodes.
		const bounds = [];
		nodes
			.filter(node => node.location) // Only use nodes with a location (although backend doesn't allow returning a node without a field_location, broken data may exist and a node with an address but without a location may be returned)
			.map(node => node.location.map(location => bounds.push(location)));

		// A very rough way to calculate a hash of all bounds by simply adding all lon/lat together.
		// Will be used as a quick hack to make the map repaint and recenter on bounds whenever the render function is called (which happens only when the nodes change).
		const boundsHash = bounds.reduce(
			(accumulator, currentValue) => accumulator + currentValue[0] + currentValue[1],
			0
		);

		return (
			<div className="Map">
				<h2>{t("Map.heading")}</h2>
				<MapContainer
					key={boundsHash}
					minZoom={limitMinZoom ? MAP_PREVIEW_MIN_ZOOM : UNLIMITED_MAP_PREVIEW_MIN_ZOOM}
					zoom={MAP_PREVIEW_DEFAULT_ZOOM}
					maxZoom={MAP_PREVIEW_MAX_ZOOM}
					zoomControl={false}
					center={bounds.length > 0 ? undefined : MAP_DEFAULT_CENTER} // Default center if there are no nodes.
					bounds={bounds.length > 0 ? bounds : undefined} // Auto bound if there are nodes.
					boundsOptions={{ padding: [36, 36] }} // 36x36 = MapNodeMarker iconSize
				>
					<TileLayer url={MAP_OSM_TILES_SERVER} />
					{nodes.length > 0 &&
						nodes
							.filter(node => node.location)
							.map(node => (
								<MapNodeMarker t={t} key={node.id} node={node} moveToNode={this.moveToNode} />
							))}
				</MapContainer>
			</div>
		);
	}
}
