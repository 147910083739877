import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Ago from "common/Ago.js";
import { notificationsPropTypes } from "common/user.js";
import "./Notifications.scss";

export default class Notifications extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		notifications: notificationsPropTypes
	};

	render() {
		const { t, getUrl, notifications } = this.props;
		const content =
			notifications && notifications.length ? (
				<ul>
					{notifications.map((notification, index) => (
						<li key={index} className={notification.read ? undefined : "unread"}>
							<Link to={getUrl("/notification/" + notification.id)}>
								<p>
									{notification.title}
									<Ago t={t} timestamp={notification.created} />
								</p>
							</Link>
						</li>
					))}
				</ul>
			) : (
				<p className="empty">{t("Notifications.empty")}</p>
			);

		return <div className="Notifications">{content}</div>;
	}
}
