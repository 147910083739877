import { PureComponent } from "react";
import PropTypes from "prop-types";
import { isCmsLink } from "common/utils.js";
import { BACKEND_DOMAIN } from "config.js";
import "./Image.scss";

export const imagePropTypes = PropTypes.exact({
	alt: PropTypes.string.isRequired,
	mimeType: PropTypes.string.isRequired,
	thumbs: PropTypes.arrayOf(
		PropTypes.exact({
			url: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired
		})
	).isRequired
});

export const getImageDerivative = (url, extension) => {
	const indexOfQuestionmark = url.lastIndexOf("?");
	return indexOfQuestionmark === -1
		? url + "." + extension
		: url.substring(0, indexOfQuestionmark) + "." + extension + url.substring(indexOfQuestionmark);
};

/**
 * Implements responsive images ERA-73.
 */
export default class Image extends PureComponent {
	static propTypes = {
		image: imagePropTypes,
		type: PropTypes.oneOf(["full", "teaser", "avatar"]).isRequired
	};

	render() {
		const { image, type } = this.props;
		if (!image) return null;
		const sizes =
			type === "full"
				? "(max-width: 640px) 100vw, 640px"
				: type === "teaser"
				? "(max-width: 640px) 100vw, (max-width: 1279px) 50vw, (max-width: 1440px) 33.3vw, 25vw"
				: undefined;
		const middleThumb = image.thumbs[Math.floor(image.thumbs.length / 2)];
		// False in storybook where we inject absolute jpg URLs from placekitten.com
		const shouldRenderWebp = isCmsLink(middleThumb.url);
		return (
			<picture className="Image">
				{shouldRenderWebp ? (
					<source
						type="image/webp"
						srcSet={image.thumbs.map(
							(thumb, index, { length }) =>
								`${BACKEND_DOMAIN}${getImageDerivative(thumb.url, "webp")} ${thumb.width}w${
									length - 1 === index ? "," : ""
								}`
						)}
						sizes={sizes}
					/>
				) : (
					""
				)}
				<source
					type={image.mimeType}
					srcSet={image.thumbs.map(
						(thumb, index, { length }) =>
							`${BACKEND_DOMAIN}${thumb.url} ${thumb.width}w${length - 1 === index ? "," : ""}`
					)}
					sizes={sizes}
				/>

				<img
					src={BACKEND_DOMAIN + middleThumb.url}
					alt={image.alt}
					width={middleThumb.width}
					height={middleThumb.height}
					loading="lazy"
					decoding="async"
				/>
			</picture>
		);
	}
}
