import PropTypes from "prop-types";
import { dealPropTypes, dealStreamPropTypes, dealMapPropTypes } from "nodes/Deal.js";
import { eventPropTypes, eventStreamPropTypes, eventMapPropTypes } from "nodes/Event.js";
import { faqPropTypes, faqStreamPropTypes } from "nodes/Faq.js";
import { organisationPropTypes, organisationStreamPropTypes, organisationMapPropTypes } from "nodes/Organisation.js";
import { pagePropTypes, pageStreamPropTypes } from "nodes/Page.js";
import { servicePropTypes, serviceStreamPropTypes } from "nodes/Service.js";
import { storyPropTypes, storyStreamPropTypes, storyMapPropTypes } from "nodes/Story.js";
import { tipPropTypes, tipStreamPropTypes, tipMapPropTypes } from "nodes/Tip.js";
import { usefulInformationPropTypes, usefulInformationStreamPropTypes } from "nodes/UsefulInformation.js";

export const nodePropTypes = PropTypes.oneOfType([
	dealPropTypes,
	eventPropTypes,
	faqPropTypes,
	organisationPropTypes,
	pagePropTypes,
	servicePropTypes,
	storyPropTypes,
	tipPropTypes,
	usefulInformationPropTypes
]);

export const streamNodePropTypes = PropTypes.oneOfType([
	dealStreamPropTypes,
	eventStreamPropTypes,
	faqStreamPropTypes,
	organisationStreamPropTypes,
	pageStreamPropTypes,
	serviceStreamPropTypes,
	storyStreamPropTypes,
	tipStreamPropTypes,
	usefulInformationStreamPropTypes
]);

export const mapNodePropTypes = PropTypes.oneOfType([
	dealMapPropTypes,
	eventMapPropTypes,
	organisationMapPropTypes,
	storyMapPropTypes,
	tipMapPropTypes
]);
