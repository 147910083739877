import { PureComponent } from "react";
import PropTypes from "prop-types";
import { latLonPropTypes } from "common/utils.js";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { MAP_OSM_TILES_SERVER } from "config.js";

const createMarkerIcon = t => {
	const className = "map-icon-apartment";
	const caption = t("Map.icon.default");
	return new L.divIcon({
		className: className,
		iconSize: [36, 36],
		iconAnchor: [18, 18],
		html: "<span>" + caption + "</span>"
	});
};

export default class AccommodationMap extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		location: latLonPropTypes.isRequired
	};

	render() {
		const { t, location } = this.props;
		return (
			<div className="Map">
				<MapContainer minZoom={10} zoom={15} maxZoom={18} zoomControl={false} center={location}>
					<TileLayer url={MAP_OSM_TILES_SERVER} />
					<Marker position={location} icon={createMarkerIcon(t)} />
				</MapContainer>
			</div>
		);
	}
}
