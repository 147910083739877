import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { loadCities } from "skeleton/DataAccess.js";
import history from "skeleton/history.js";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const sorts = [
	{ key: "rent_asc", by: "rent", order: "asc" },
	{ key: "rent_desc", by: "rent", order: "desc" }
];

export default class AccommodationFilters extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired,
		cityTitleSearchParam: PropTypes.string,
		housingTypesSearchParam: PropTypes.string,
		housingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
		qualityLabelsSearchParam: PropTypes.string,
		qualityLabels: PropTypes.arrayOf(
			PropTypes.exact({
				name: PropTypes.string.isRequired,
				icon: PropTypes.string.isRequired,
				criteria: PropTypes.string.isRequired
			})
		).isRequired,
		bedroomCountMinSearchParam: PropTypes.number,
		bedroomCountMaxSearchParam: PropTypes.number,
		totalSizeMinSearchParam: PropTypes.number,
		totalSizeMaxSearchParam: PropTypes.number,
		rentMinSearchParam: PropTypes.number,
		rentMaxSearchParam: PropTypes.number,
		sortBySearchParam: PropTypes.string,
		sortOrderSearchParam: PropTypes.string,
		resultsSize: PropTypes.number.isRequired
	};

	state = {
		autosuggestValue: this.props.cityTitleSearchParam ? this.props.cityTitleSearchParam : "",
		autosuggestItems: [],
		bedroomCountMin: this.props.bedroomCountMinSearchParam,
		bedroomCountMax: this.props.bedroomCountMaxSearchParam,
		totalSizeMin: this.props.totalSizeMinSearchParam,
		totalSizeMax: this.props.totalSizeMaxSearchParam,
		rentMin: this.props.rentMinSearchParam,
		rentMax: this.props.rentMaxSearchParam
	};

	setCitySearchParameter = cityTitle => {
		history.replace(
			this.props.getUrl("/accommodation/filters", {
				cityTitle: cityTitle ? cityTitle : undefined
			})
		);
	};

	renderCity = () => {
		const { t, getUrl, url, cityTitleSearchParam } = this.props;
		const { autosuggestValue, autosuggestItems } = this.state;
		return (
			<div className="editableField">
				<h2>{t("Filters.city")}</h2>
				<div>
					{url === "/accommodation/filters/city" ? (
						<Fragment>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadCities(value).then(cities => this.setState({ autosuggestItems: cities }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) => {
									this.setState({ autosuggestValue: suggestion.title });
									this.setCitySearchParameter(suggestion.title);
								}}
								getSuggestionValue={city => city.title}
								renderSuggestion={city => city.title}
								inputProps={{
									placeholder: t("Filters.city_placeholder"),
									value: autosuggestValue,
									onChange: (event, { newValue }) => this.setState({ autosuggestValue: newValue }),
									onBlur: () => autosuggestValue === "" && this.setCitySearchParameter(undefined),
									onKeyDown: e =>
										e.key === "Enter" &&
										autosuggestValue === "" &&
										this.setCitySearchParameter(undefined),
									maxLength: 32
								}}
							/>
							<Link
								to={getUrl("/accommodation/filters", {
									cityTitle: cityTitleSearchParam ? cityTitleSearchParam : undefined
								})}
								role="button"
								className="back"
							>
								{t("back")}
							</Link>
							<Link
								to={getUrl("/accommodation/filters/city", { cityTitle: undefined })}
								role="button"
								className="reset"
								onClick={() => this.setState({ autosuggestValue: "" })}
							>
								{t("reset")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<p>{autosuggestValue || t("Filters.city_empty")}</p>
							<Link to={getUrl("/accommodation/filters/city")} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	getHousingTypeSearchParameterFor = filterHousingType =>
		this.housingTypeSearchParameterHas(filterHousingType)
			? this.getHousingTypeSearchParameterWithout(filterHousingType)
			: this.getHousingTypeSearchParameterWith(filterHousingType);

	housingTypeSearchParameterHas = housingType =>
		this.props.housingTypesSearchParam !== undefined &&
		this.props.housingTypesSearchParam.split("-").includes(housingType);

	getHousingTypeSearchParameterWith = housingType =>
		this.props.housingTypesSearchParam === undefined
			? housingType
			: this.props.housingTypesSearchParam.split("-").concat(housingType).sort().join("-");

	getHousingTypeSearchParameterWithout = housingType =>
		this.props.housingTypesSearchParam === undefined || this.props.housingTypesSearchParam === housingType
			? undefined
			: this.props.housingTypesSearchParam
					.split("-")
					.filter(item => item !== housingType)
					.join("-");

	renderHousingTypes = () => {
		const { t, getUrl, url, housingTypesSearchParam, housingTypes } = this.props;
		const sanitizedFilterHousingTypes = (housingTypesSearchParam || "")
			.split("-")
			.filter(value => housingTypes.includes(value));

		return (
			<div className="editableField">
				<h2>{t("Accommodation.Filters.housing_types")}</h2>
				{url === "/accommodation/filters/housing-types" ? (
					<div>
						<ul>
							{housingTypes.map(filterHousingType => (
								<li key={filterHousingType}>
									<Link
										to={getUrl("/accommodation/filters/housing-types", {
											housingTypes: this.getHousingTypeSearchParameterFor(filterHousingType)
										})}
										aria-selected={this.housingTypeSearchParameterHas(filterHousingType)}
										replace
										className={`switch${
											this.housingTypeSearchParameterHas(filterHousingType) ? " active" : ""
										}`}
									>
										{t("Accommodation.Filters.housing_types_options." + filterHousingType)}
									</Link>
								</li>
							))}
						</ul>
						<Link
							to={getUrl("/accommodation/filters", { housingTypes: housingTypesSearchParam })}
							role="button"
							className="back"
						>
							{t("back")}
						</Link>
						<Link
							to={getUrl("/accommodation/filters/housing-types", { housingTypes: undefined })}
							role="button"
							className="reset"
						>
							{t("reset")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{sanitizedFilterHousingTypes.length
								? sanitizedFilterHousingTypes
										.map(key => t("Accommodation.Filters.housing_types_options." + key))
										.join(", ")
								: t("Accommodation.Filters.housing_types_empty")}
						</p>
						<Link to={getUrl("/accommodation/filters/housing-types")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	getQualityLabelSearchParameterFor = filterQualityLabel =>
		this.qualityLabelSearchParameterHas(filterQualityLabel)
			? this.getQualityLabelSearchParameterWithout(filterQualityLabel)
			: this.getQualityLabelSearchParameterWith(filterQualityLabel);

	qualityLabelSearchParameterHas = qualityLabelId =>
		this.props.qualityLabelsSearchParam !== undefined &&
		this.props.qualityLabelsSearchParam.split("-").includes(qualityLabelId.toString());

	getQualityLabelSearchParameterWith = qualityLabelId =>
		this.props.qualityLabelsSearchParam === undefined
			? qualityLabelId
			: this.props.qualityLabelsSearchParam.split("-").concat(qualityLabelId).sort().join("-");

	getQualityLabelSearchParameterWithout = qualityLabelId =>
		this.props.qualityLabelsSearchParam === undefined ||
		this.props.qualityLabelsSearchParam === qualityLabelId.toString()
			? undefined
			: this.props.qualityLabelsSearchParam
					.split("-")
					.filter(item => item !== qualityLabelId.toString())
					.join("-");

	renderQualityLabels = () => {
		const { t, getUrl, url, qualityLabelsSearchParam, qualityLabels } = this.props;
		const filterQualityLabels = (qualityLabelsSearchParam || "")
			.split("-")
			.map(filterQualityLabelId => qualityLabels[Number.parseInt(filterQualityLabelId)])
			.filter(filterQualityLabelId => filterQualityLabelId !== undefined); // Remove missing.
		return (
			<div className="editableField">
				<h2>{t("Accommodation.Filters.quality_labels")}</h2>
				{url === "/accommodation/filters/quality-labels" ? (
					<div>
						<ul>
							{qualityLabels.map((qualityLabel, index) => (
								<li key={index}>
									<Link
										to={getUrl("/accommodation/filters/quality-labels", {
											qualityLabels: this.getQualityLabelSearchParameterFor(index)
										})}
										aria-selected={this.qualityLabelSearchParameterHas(index)}
										replace
										className={`switch${
											this.qualityLabelSearchParameterHas(index) ? " active" : ""
										}`}
									>
										{qualityLabel.name}
									</Link>
								</li>
							))}
						</ul>
						<Link
							to={getUrl("/accommodation/filters", { qualityLabels: qualityLabelsSearchParam })}
							role="button"
							className="back"
						>
							{t("back")}
						</Link>
						<Link
							to={getUrl("/accommodation/filters/quality-labels", { qualityLabels: undefined })}
							role="button"
							className="reset"
						>
							{t("reset")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{filterQualityLabels.length
								? filterQualityLabels.map(filterQualityLabel => filterQualityLabel.name).join(", ")
								: t("Accommodation.Filters.quality_labels_empty")}
						</p>
						<Link to={getUrl("/accommodation/filters/quality-labels")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	setSliderParameters = (urlLabel, labelParams, moveBack = false) => {
		const minLabel = labelParams + "Min";
		const maxLabel = labelParams + "Max";

		const minValue = !isNaN(this.state[minLabel]) ? this.state[minLabel] : undefined;
		const maxValue = !isNaN(this.state[maxLabel]) ? this.state[maxLabel] : undefined;

		const newParams = {};
		if (minValue !== undefined && maxValue !== undefined) {
			newParams[minLabel] = minValue;
			newParams[maxLabel] = maxValue;
		}
		history.replace(this.props.getUrl("/accommodation/filters" + (moveBack ? "" : "/" + urlLabel), newParams));
	};

	resetSliderParameters = (urlLabel, labelParams) => {
		const minLabel = labelParams + "Min";
		const maxLabel = labelParams + "Max";
		this.setState({ [minLabel]: undefined, [maxLabel]: undefined });
		history.replace(
			this.props.getUrl("/accommodation/filters/" + urlLabel, { [minLabel]: undefined, [maxLabel]: undefined })
		);
	};

	onSliderChange = (value, labelParams) => {
		const minLabel = labelParams + "Min";
		const maxLabel = labelParams + "Max";
		this.setState({
			[minLabel]: value[0],
			[maxLabel]: value[1]
		});
	};

	renderSlider = (label, labelParams, urlLabel, min, max, factor = 1) => {
		const { t, getUrl, url } = this.props;
		const minLabel = labelParams + "Min";
		const maxLabel = labelParams + "Max";
		return (
			<div className="editableField inputSlider">
				<h2>{t("Accommodation.Filters." + label)}</h2>
				<div>
					{url === "/accommodation/filters/" + urlLabel ? (
						<Fragment>
							<Slider
								range
								allowCross={false}
								min={min}
								max={max}
								value={[this.state[minLabel], this.state[maxLabel]]}
								onChange={e => this.onSliderChange(e, labelParams)}
								onChangeComplete={() => this.setSliderParameters(urlLabel, labelParams)}
							/>
							<div className="slider-inputs">
								<div>
									<h3>{t("Accommodation.Filters.min")}</h3>
									<input
										id={"min-" + labelParams}
										type="number"
										disabled
										name={"min-" + labelParams}
										value={!isNaN(this.state[minLabel]) ? this.state[minLabel] : 0}
									/>
								</div>
								<div>
									<h3>{t("Accommodation.Filters.max")}</h3>
									<input
										id={"max-" + labelParams}
										type="number"
										disabled
										name={"max-" + labelParams}
										value={!isNaN(this.state[maxLabel]) ? this.state[maxLabel] : 0}
									/>
								</div>
							</div>
							<button
								className="back"
								onClick={() => this.setSliderParameters(urlLabel, labelParams, true)}
							>
								{t("back")}
							</button>
							<button onClick={() => this.resetSliderParameters(urlLabel, labelParams)} className="reset">
								{t("reset")}
							</button>
						</Fragment>
					) : (
						<Fragment>
							{this.state[minLabel] === undefined || this.state[maxLabel] === undefined ? (
								<p>{t("Accommodation.Filters." + label + "_empty")}</p>
							) : (
								<p>
									{this.state[minLabel]} - {this.state[maxLabel]}
								</p>
							)}
							<Link to={getUrl("/accommodation/filters/" + urlLabel)} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	handleSortChange(event) {
		history.replace(
			this.props.getUrl("/accommodation/filters/sort", {
				sortBy: event.target.value ? event.target.value.split("_")[0] : undefined,
				sortOrder: event.target.value ? event.target.value.split("_")[1] : undefined
			})
		);
	}

	renderSort = () => {
		const { t, getUrl, url, sortBySearchParam, sortOrderSearchParam } = this.props;
		return (
			<div className="editableField">
				<h2>{t("Accommodation.Filters.sort")}</h2>
				{url === "/accommodation/filters/sort" ? (
					<div>
						<select
							value={`${sortBySearchParam}_${sortOrderSearchParam}`}
							onChange={this.handleSortChange.bind(this)}
						>
							<option value="">{t("Accommodation.Filters.sort_empty")}</option>
							{sorts.map(sort => (
								<option key={sort.key} value={`${sort.by}_${sort.order}`}>
									{t("Accommodation.Filters.sort_options." + sort.key)}
								</option>
							))}
						</select>
						<Link
							to={getUrl("/accommodation/filters", {
								sortBy: sortBySearchParam,
								sortOrder: sortOrderSearchParam
							})}
							role="button"
							className="back"
						>
							{t("back")}
						</Link>
						<Link
							to={getUrl("/accommodation/filters/sort", { sortBy: undefined, sortOrder: undefined })}
							role="button"
							className="reset"
						>
							{t("reset")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{sortBySearchParam && sortOrderSearchParam
								? t(
										"Accommodation.Filters.sort_options." +
											sortBySearchParam +
											"_" +
											sortOrderSearchParam
								  )
								: t("Accommodation.Filters.sort_empty")}
						</p>
						<Link to={getUrl("/accommodation/filters/sort")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	renderSummary = () => (
		<p className="summary small-text">
			{this.props.t.map("Filters.summary", this.props.resultsSize, {
				_RESULT_: <strong>{this.props.resultsSize}</strong>
			})}
		</p>
	);

	render = () => (
		<Fragment>
			{this.renderCity()}
			{this.renderHousingTypes()}
			{this.renderQualityLabels()}
			{this.renderSlider("bedroom_count", "bedroomCount", "bedroom-count", 0, 10)}
			{this.renderSlider("total_size", "totalSize", "total-size", 0, 400)}
			{this.renderSlider("rent", "rent", "rent", 0, 4000)}
			{this.renderSort()}
			{this.renderSummary()}
		</Fragment>
	);
}
