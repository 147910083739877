import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reportNode, handleError } from "skeleton/DataAccess.js";
import { userPropTypes } from "common/user.js";
import "./ReportNodeButton.scss";

export default class ReportNodeButton extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		user: userPropTypes,
		node: PropTypes.object.isRequired // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	state = {
		reported: false
	};

	reportClicked = () => {
		const { t, node } = this.props;
		if (!window.confirm(t("ReportNodeButton.confirm"))) return;
		reportNode(node.id)
			.then(() => this.setState({ reported: true }))
			.catch(error => handleError(t, error));
	};

	render() {
		const { t, user } = this.props;
		const { reported } = this.state;

		if (!user) return null;

		return (
			<div className="ReportNodeButton">
				<button
					onClick={this.reportClicked}
					title={t("ReportNodeButton.title")}
					disabled={reported ? "disabled" : null}
					className={reported ? "reported" : undefined}
				>
					{t(reported ? "ReportNodeButton.reported" : "ReportNodeButton.report")}
				</button>
			</div>
		);
	}
}
