import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { userPropTypes, userTeaserPropTypes } from "common/user.js";
import Image, { imagePropTypes } from "common/Image.js";
import Languages from "common/Languages.js";
import { termPropTypes } from "common/Term.js";
import { latLonPropTypes } from "common/utils.js";
import Meta from "common/Meta.js";
import NodeTopMeta from "common/NodeTopMeta.js";
import NodePublishingMeta from "common/NodePublishingMeta.js";
import { websitePropTypes } from "common/Website.js";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "common/utils.js";
import ReportNodeButton from "common/ReportNodeButton.js";

export const tipPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["tip"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	body: PropTypes.string.isRequired,
	summary: PropTypes.string.isRequired,
	miniSummary: PropTypes.string.isRequired,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	tags: PropTypes.arrayOf(termPropTypes),
	website: websitePropTypes
});

export const tipStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["tip"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	tags: PropTypes.arrayOf(termPropTypes)
});

export const tipMapPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["tip"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	location: PropTypes.arrayOf(latLonPropTypes) // from relation
});

export default class Tip extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: tipPropTypes,
		markNodeElement: PropTypes.element,
		deleteNodeElement: PropTypes.element
	};

	render() {
		const { t, getUrl, user, node, markNodeElement, deleteNodeElement } = this.props;
		return (
			<Fragment>
				<Languages
					t={t}
					getUrl={getUrl}
					languages={node.languages}
					language={node.language}
					alias={node.alias}
				/>
				<Image image={node.image} type="full" />
				<NodeTopMeta t={t} getUrl={getUrl} node={node} />
				<ClickableDrupalContent content={node.body} />
				<Meta t={t} item={node} />
				{markNodeElement}
				{deleteNodeElement}
				<ReportNodeButton t={t} user={user} node={node} />
			</Fragment>
		);
	}
}

export class TipTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: tipStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<NodePublishingMeta t={t} node={node} />
				<div className="content">
					<Image image={node.image} type="teaser" />
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
