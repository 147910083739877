import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { serializeFormToFormData } from "common/utils.js";
import autosize from "autosize";
import "./Form.scss";
import { termPropTypes } from "common/Term.js";
import { userPropTypes, getLinkToLoginPage } from "common/user.js";
import Autosuggest from "react-autosuggest";
import { loadCities, loadOrganisations, submitForm } from "skeleton/DataAccess.js";

export const formPropTypes = PropTypes.exact({
	id: PropTypes.oneOf(["feedback", "tip", "story", "living_cost", "experience"]).isRequired,
	metadata: PropTypes.exact({
		title: PropTypes.string.isRequired,
		fields: PropTypes.arrayOf(
			PropTypes.exact({
				name: PropTypes.string.isRequired,
				type: PropTypes.oneOf([
					"text",
					"email",
					"textarea",
					"radio",
					"select",
					"checkbox",
					"file",
					"suggest",
					"number"
				]).isRequired,
				label: PropTypes.string,
				htmlLabel: PropTypes.string,
				labelIsHtml: PropTypes.bool,
				minlength: PropTypes.number,
				maxlength: PropTypes.number,
				min: PropTypes.number,
				max: PropTypes.number,
				required: PropTypes.bool.isRequired,
				accept: PropTypes.arrayOf(PropTypes.string),
				options: PropTypes.arrayOf(termPropTypes)
			}).isRequired
		),
		uploadMaxSize: PropTypes.number
	}).isRequired
});

export default class Form extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		form: formPropTypes,
		user: userPropTypes
	};

	state = {
		submissionStatus: undefined // ['warning-uploadMaxSize', 'pending', 'success', 'error']
	};

	shouldFormBeDisabled = () => ["warning-uploadMaxSize", "pending"].includes(this.state.submissionStatus);

	onAutosuggestChange = (fieldName, newValue) => {
		this.setState({ ["autosuggestValue-" + fieldName]: newValue });
		this["autosuggestInput-" + fieldName].value = "";
	};

	onAutosuggestFetchRequested = (fieldName, { value }) => {
		// Hardcoded data loading function based on field name. Could be improved.
		if (fieldName === "city")
			loadCities(value).then(values => this.setState({ ["autosuggestItems-" + fieldName]: values }));
		if (fieldName === "organisation" || fieldName === "host_organisation")
			loadOrganisations(value).then(values => this.setState({ ["autosuggestItems-" + fieldName]: values }));
	};

	onAutosuggestClearRequested = fieldName => this.setState({ ["autosuggestItems-" + fieldName]: [] });

	onAutosuggestSelected = (fieldName, suggestion) => (this["autosuggestInput-" + fieldName].value = suggestion.id);

	onAutosuggestBlur = fieldName =>
		this["autosuggestInput-" + fieldName].value === "" &&
		this.setState({ ["autosuggestValue-" + fieldName]: undefined });

	enhanceDomForm = () => {
		// Check if form exists because we may have redirected to the login page via the render function.
		if (this.formDom) {
			// Apply autosize to auto grow textareas.
			this.formDom.querySelectorAll("textarea[name]").forEach(textarea => autosize(textarea));
		}
	};

	componentDidMount() {
		this.enhanceDomForm();
	}

	onSubmit = e => {
		e.preventDefault();
		const formData = serializeFormToFormData(this.formDom);
		this.setState({ submissionStatus: "pending" });
		submitForm(this.props.form.id, formData)
			.then(() => {
				this.setState({ submissionStatus: "success" });
			})
			.catch(() => {
				this.setState({ submissionStatus: "error" });
			});
	};

	/**
	 * Calculates total post size and warns user if it has exceeded what the server
	 * is wiling to accept.
	 */
	checkFormPostSize = () => {
		let total = 0;
		this.formDom.querySelectorAll("input[name][type=file]").forEach(field => {
			Array.from(field.files).forEach(file => {
				total += file.size;
			});
		});
		if (total >= this.props.form.metadata.uploadMaxSize) {
			this.setState({ submissionStatus: "warning-uploadMaxSize" });
		} else {
			// Reset status only if previous status was the uploadMaxSize warning.
			if (this.state.submissionStatus === "warning-uploadMaxSize") {
				this.setState({ submissionStatus: undefined });
			}
		}
	};

	render() {
		const { t, form, user } = this.props;
		const { submissionStatus } = this.state;

		// Some forms are only available to logged in users.
		if (!user && ["tip", "story", "living_cost", "experience"].includes(form.id)) {
			return <Redirect to={getLinkToLoginPage()} />;
		}

		const renderField = field => (
			<div key={field.name} className={`field-type-${field.type} field-name-${field.name}`}>
				{/* checkbox is the only one that, for styling reasons, requires the input before the label */}
				{field.type === "checkbox" && (
					<input
						id={`field-name-${field.name}`}
						type="checkbox"
						name={field.name}
						required={field.required}
					/>
				)}
				<label htmlFor={`field-name-${field.name}`} className={field.required ? "required" : undefined}>
					{field.label}
					{field.htmlLabel && <div dangerouslySetInnerHTML={{ __html: field.htmlLabel }} />}
					{field.required && <span>{t("Form.required")}</span>}
				</label>
				{field.type === "text" && (
					<input
						id={`field-name-${field.name}`}
						type="text"
						minLength={field.minlength}
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "email" && (
					<input
						id={`field-name-${field.name}`}
						type="email"
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "number" && (
					<input
						id={`field-name-${field.name}`}
						type="number"
						min={field.min}
						max={field.max}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "textarea" && (
					<textarea
						id={`field-name-${field.name}`}
						minLength={field.minlength}
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
						rows="1" // in order to have the same height as the inputs
					/>
				)}
				{field.type === "radio" &&
					field.options.map((option, index) => (
						<label key={index} className="radio-option">
							<input type="radio" name={field.name} required={field.required} value={option} />
							<span>{option}</span>
						</label>
					))}

				{field.type === "select" && (
					<select id={`field-name-${field.name}`} name={field.name} required={field.required}>
						<option value=""></option>
						{field.options.map(option => (
							<option key={option.id} value={option.id}>
								{option.title}
							</option>
						))}
					</select>
				)}
				{field.type === "file" && (
					<input
						id={`field-name-${field.name}`}
						type="file"
						name={field.name}
						required={field.required}
						accept={field.accept.join(",")}
						onChange={this.checkFormPostSize}
					/>
				)}
				{field.type === "suggest" && (
					<Fragment>
						<input
							id={`field-name-${field.name}`}
							type="input"
							name={field.name}
							ref={node => (this["autosuggestInput-" + field.name] = node)}
							style={{ display: "none" }}
						/>
						<Autosuggest
							suggestions={this.state["autosuggestItems-" + field.name] || []}
							onSuggestionsFetchRequested={value => this.onAutosuggestFetchRequested(field.name, value)}
							onSuggestionsClearRequested={() => this.onAutosuggestClearRequested(field.name)}
							onSuggestionSelected={(event, { suggestion }) =>
								this.onAutosuggestSelected(field.name, suggestion)
							}
							getSuggestionValue={term => term.title}
							renderSuggestion={term => term.title}
							inputProps={{
								required: field.required,
								value: this.state["autosuggestValue-" + field.name] || "",
								onChange: (event, { newValue }) => this.onAutosuggestChange(field.name, newValue),
								onBlur: () => this.onAutosuggestBlur(field.name),
								maxLength: 32
							}}
						/>
					</Fragment>
				)}
			</div>
		);

		const renderForm = fields => (
			<form
				method="post"
				className={`Form ${form.id}`}
				onSubmit={this.onSubmit}
				ref={form => (this.formDom = form)}
			>
				<fieldset>
					<legend>{form.metadata.title}</legend>
					{submissionStatus !== "success" && <div className="fields">{fields}</div>}
					{submissionStatus === "warning-uploadMaxSize" && (
						<div className="message warning">
							<p>
								{t("Form.submissionStatus.warning-uploadMaxSize", {
									uploadMaxSize: form.metadata.uploadMaxSize
								})}
							</p>
						</div>
					)}
					{submissionStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{submissionStatus === "success" && (
						<div className="message success">
							<p>{t("Form.submissionStatus.success")}</p>
						</div>
					)}
					{submissionStatus === "error" && (
						<div className="message error">
							<p>{t("Form.submissionStatus.error")}</p>
						</div>
					)}
					<div className="actions">
						{submissionStatus !== "success" && (
							<button className="button" type="submit" disabled={this.shouldFormBeDisabled()}>
								{t("Form.submit")}
							</button>
						)}
					</div>
				</fieldset>
			</form>
		);

		return renderForm(form.metadata.fields.map(field => renderField(field)));
	}
}
