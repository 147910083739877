import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";
import "./ApplicationRequirements.scss";

const applicationRequirementUrl = PropTypes.exact({
	url: PropTypes.string.isRequired,
	title: PropTypes.string
});

export const applicationRequirementsPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		applicationsAutumn: PropTypes.string,
		applicationsSpring: PropTypes.string,
		email: PropTypes.string,
		nominationsAutumn: PropTypes.string,
		nominationsSpring: PropTypes.string,
		outProcessAutumnEnd: PropTypes.string,
		outProcessAutumnStart: PropTypes.string,
		outProcessSpringEnd: PropTypes.string,
		outProcessSpringStart: PropTypes.string,
		phone: PropTypes.string,
		outCvUrl: applicationRequirementUrl,
		outCvDescription: PropTypes.string,
		outMotivationLetterDescription: PropTypes.string,
		outMotivationLetterUrl: applicationRequirementUrl,
		outPortfolioDescription: PropTypes.string,
		outPortfolioUrl: applicationRequirementUrl,
		outEctsRequirement: PropTypes.string,
		outEctsUrl: applicationRequirementUrl,
		outIscedUrl: applicationRequirementUrl,
		outIsced: termPropTypes,
		outEqf: termPropTypes,
		outEqfUrl: applicationRequirementUrl,
		outReferenceDescription: PropTypes.string,
		outReferenceUrl: applicationRequirementUrl,
		outTranscriptDescription: PropTypes.string,
		outTranscriptUrl: applicationRequirementUrl,
		outRecommendationLetterDescription: PropTypes.string,
		outRecommendationLetterUrl: applicationRequirementUrl,
		outExternalCertificationDescription: PropTypes.string,
		outExternalCertificationUrl: applicationRequirementUrl,
		languageRequirements: PropTypes.arrayOf(
			PropTypes.exact({ description: PropTypes.string, languageLevel: termPropTypes, language: termPropTypes })
		),
		otherRequirements: PropTypes.arrayOf(PropTypes.exact({ description: PropTypes.string })),
		otherDocuments: PropTypes.arrayOf(PropTypes.exact({ description: PropTypes.string }))
	})
);

export default class ApplicationRequirements extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		applicationRequirements: applicationRequirementsPropTypes
	};

	render() {
		const { t, applicationRequirements } = this.props;
		if (!applicationRequirements || !applicationRequirements.length) return null;

		const renderNominationsInfo = applicationRequirement => {
			if (!applicationRequirement.nominationsAutumn && !applicationRequirement.nominationsSpring) return null;
			return (
				<li>
					<label>{t("ApplicationRequirements.nominations")}</label>
					<p className="small-text">
						{applicationRequirement.nominationsAutumn && (
							<span>
								{t("ApplicationRequirements.autumn")} {applicationRequirement.nominationsAutumn}
							</span>
						)}
						{applicationRequirement.nominationsSpring && (
							<span>
								{t("ApplicationRequirements.spring")} {applicationRequirement.nominationsSpring}
							</span>
						)}
					</p>
				</li>
			);
		};

		const renderApplicationsInfo = applicationRequirement => {
			if (!applicationRequirement.applicationsAutumn && !applicationRequirement.applicationsSpring) return null;
			return (
				<li>
					<label>{t("ApplicationRequirements.applications")}</label>
					<p className="small-text">
						{applicationRequirement.applicationsAutumn && (
							<span>
								{t("ApplicationRequirements.autumn")} {applicationRequirement.applicationsAutumn}
							</span>
						)}
						{applicationRequirement.applicationsSpring && (
							<span>
								{t("ApplicationRequirements.spring")} {applicationRequirement.applicationsSpring}
							</span>
						)}
					</p>
				</li>
			);
		};

		const renderProcessInfoStart = applicationRequirement => {
			if (!applicationRequirement.outProcessAutumnStart && !applicationRequirement.outProcessSpringStart)
				return null;
			return (
				<li>
					<label>{t("ApplicationRequirements.process.start")}</label>
					<p className="small-text">
						{applicationRequirement.outProcessAutumnStart && (
							<span>
								{t("ApplicationRequirements.autumn")} {applicationRequirement.outProcessAutumnStart}
							</span>
						)}
						{applicationRequirement.outProcessSpringStart && (
							<span>
								{t("ApplicationRequirements.spring")} {applicationRequirement.outProcessSpringStart}
							</span>
						)}
					</p>
				</li>
			);
		};

		const renderProcessInfoEnd = applicationRequirement => {
			if (!applicationRequirement.outProcessAutumnEnd && !applicationRequirement.outProcessSpringEnd) return null;
			return (
				<li>
					<label>{t("ApplicationRequirements.process.end")}</label>
					<p className="small-text">
						{applicationRequirement.outProcessAutumnEnd && (
							<span>
								{t("ApplicationRequirements.autumn")} {applicationRequirement.outProcessAutumnEnd}
							</span>
						)}
						{applicationRequirement.outProcessSpringEnd && (
							<span>
								{t("ApplicationRequirements.spring")} {applicationRequirement.outProcessSpringEnd}
							</span>
						)}
					</p>
				</li>
			);
		};

		const renderProcedureInfo = applicationRequirement => {
			if (!applicationRequirement.email && !applicationRequirement.phone) return null;
			return (
				<div className="procedure">
					<h2>{t("ApplicationRequirements.procedure")}</h2>
					<ul className="more-info">
						<li>
							{applicationRequirement.email && (
								<Fragment>
									<label>{t("email")}</label>
									<span>{applicationRequirement.email}</span>
								</Fragment>
							)}
						</li>
						<li>
							{applicationRequirement.phone && (
								<Fragment>
									<label>{t("ApplicationRequirements.phone")}</label>
									<span>{applicationRequirement.phone}</span>
								</Fragment>
							)}
						</li>
					</ul>
				</div>
			);
		};

		const renderCvRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.cv")}</h3>
					<p className="small-text">{applicationRequirement.outCvDescription}</p>
					{applicationRequirement.outCvUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outCvUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderIscedRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.isced")}</h3>
					<p className="small-text">{applicationRequirement.outIsced.title}</p>
					{applicationRequirement.outIscedUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outIscedUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderMotivationLetterRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.motivation_letter")}</h3>
					<p className="small-text">{applicationRequirement.outMotivationLetterDescription}</p>
					{applicationRequirement.outMotivationLetterUrl && (
						<p className="small-text">
							<a
								href={applicationRequirement.outMotivationLetterUrl.url}
								rel="external noopener noreferrer"
							>
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderPortfolioRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.portfolio")}</h3>
					<p className="small-text">{applicationRequirement.outPortfolioDescription}</p>
					{applicationRequirement.outPortfolioUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outPortfolioUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderEctsRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.ects")}</h3>
					<p className="small-text">{applicationRequirement.outEctsRequirement}</p>
					{applicationRequirement.outEctsUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outEctsUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderEqfRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.eqf")}</h3>
					<p className="small-text">{applicationRequirement.outEqf.title}</p>
					{applicationRequirement.outEqfUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outEqfUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderReferenceRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.reference")}</h3>
					<p className="small-text">{applicationRequirement.outReferenceDescription}</p>
					{applicationRequirement.outReferenceUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outReferenceUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderTranscriptRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.transcript")}</h3>
					<p className="small-text">{applicationRequirement.outTranscriptDescription}</p>
					{applicationRequirement.outTranscriptUrl && (
						<p className="small-text">
							<a href={applicationRequirement.outTranscriptUrl.url} rel="external noopener noreferrer">
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderRecommendationLetterRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.recommendation_letter")}</h3>
					<p className="small-text">{applicationRequirement.outRecommendationLetterDescription}</p>
					{applicationRequirement.outRecommendationLetterUrl && (
						<p className="small-text">
							<a
								href={applicationRequirement.outRecommendationLetterUrl.url}
								rel="external noopener noreferrer"
							>
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderExternalCertificationRequirements = applicationRequirement => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.external_certification")}</h3>
					<p className="small-text">{applicationRequirement.outExternalCertificationDescription}</p>
					{applicationRequirement.outExternalCertificationUrl && (
						<p className="small-text">
							<a
								href={applicationRequirement.outExternalCertificationUrl.url}
								rel="external noopener noreferrer"
							>
								{t("moreinfo")}
							</a>
						</p>
					)}
				</Fragment>
			);
		};

		const renderLanguageRequirements = languageRequirements => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.language_requirements")}</h3>
					{languageRequirements.map((languageRequirement, index) => (
						<div className="language" key={index}>
							<p>
								{languageRequirement.language && (
									<Fragment>{languageRequirement.language.title}</Fragment>
								)}
								{languageRequirement.languageLevel && (
									<Fragment>{` - ${languageRequirement.languageLevel.title}`}</Fragment>
								)}
							</p>
							<p className="small-text">{languageRequirement.description}</p>
						</div>
					))}
				</Fragment>
			);
		};

		const renderOtherRequirements = otherRequirements => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.other_requirements")}</h3>
					{otherRequirements.map((otherRequirement, index) => (
						<Fragment key={index}>{otherRequirement.description}</Fragment>
					))}
				</Fragment>
			);
		};

		const renderotherDocumentsRequirements = otherDocumentsRequirements => {
			return (
				<Fragment>
					<h3>{t("ApplicationRequirements.other_documents")}</h3>
					{otherDocumentsRequirements.map((otherDocumentsRequirement, index) => (
						<Fragment key={index}>{otherDocumentsRequirement.description}</Fragment>
					))}
				</Fragment>
			);
		};

		const renderRequirements = applicationRequirement => {
			if (
				!applicationRequirement.outCvDescription &&
				!applicationRequirement.outIsced &&
				!applicationRequirement.outMotivationLetterDescription &&
				!applicationRequirement.outPortfolioDescription &&
				!applicationRequirement.outEqf &&
				!applicationRequirement.outReferenceDescription &&
				!applicationRequirement.outTranscriptDescription &&
				!applicationRequirement.outRecommendationLetterDescription &&
				!applicationRequirement.outExternalCertificationDescription &&
				!applicationRequirement.outEctsRequirement &&
				!applicationRequirement.languageRequirements &&
				!applicationRequirement.otherDocuments &&
				!applicationRequirement.otherRequirements
			)
				return null;
			return (
				<Fragment>
					{applicationRequirement.outCvDescription && <li>{renderCvRequirements(applicationRequirement)}</li>}
					{applicationRequirement.outIsced && <li>{renderIscedRequirements(applicationRequirement)}</li>}
					{applicationRequirement.outMotivationLetterDescription && (
						<li>{renderMotivationLetterRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outPortfolioDescription && (
						<li>{renderPortfolioRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outEctsRequirement && (
						<li>{renderEctsRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outEqf && <li>{renderEqfRequirements(applicationRequirement)}</li>}
					{applicationRequirement.outReferenceDescription && (
						<li>{renderReferenceRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outTranscriptDescription && (
						<li>{renderTranscriptRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outRecommendationLetterDescription && (
						<li>{renderRecommendationLetterRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.outExternalCertificationDescription && (
						<li>{renderExternalCertificationRequirements(applicationRequirement)}</li>
					)}
					{applicationRequirement.languageRequirements && (
						<li>{renderLanguageRequirements(applicationRequirement.languageRequirements)}</li>
					)}
					{applicationRequirement.otherDocuments && (
						<li>{renderotherDocumentsRequirements(applicationRequirement.otherDocuments)}</li>
					)}
					{applicationRequirement.otherRequirements && (
						<li>{renderOtherRequirements(applicationRequirement.otherRequirements)}</li>
					)}
				</Fragment>
			);
		};

		return (
			<div className="ApplicationRequirements">
				{applicationRequirements.map((applicationRequirement, index) => (
					<Fragment key={index}>
						<div className="calendar">
							<h2>{t("ApplicationRequirements.label.calendar")}</h2>
							<ul className="more-info">
								{renderNominationsInfo(applicationRequirement)}
								{renderApplicationsInfo(applicationRequirement)}
								{renderProcessInfoStart(applicationRequirement)}
								{renderProcessInfoEnd(applicationRequirement)}
							</ul>
						</div>
						{renderProcedureInfo(applicationRequirement)}
						<div className="requirements">
							<h2>{t("ApplicationRequirements.label.requirements")}</h2>
							<ul>{renderRequirements(applicationRequirement)}</ul>
						</div>
					</Fragment>
				))}
			</div>
		);
	}
}
