import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { loadCities } from "skeleton/DataAccess.js";
import history from "skeleton/history.js";
import { termPropTypes } from "common/Term.js";

export default class Filters extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired,
		cityFilter: termPropTypes,
		contentTypesSearchParam: PropTypes.string,
		filterContentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
		tagsSearchParam: PropTypes.string,
		tags: PropTypes.arrayOf(termPropTypes),
		resultsSize: PropTypes.number.isRequired
	};

	state = {
		autosuggestValue: this.props.cityFilter ? this.props.cityFilter.title : "",
		autosuggestItems: []
	};

	setCitySearchParameter = city =>
		history.replace(this.props.getUrl("/filters", { city: city ? city.id : undefined }));

	renderCity = () => {
		const { t, getUrl, url, cityFilter } = this.props;
		const { autosuggestValue, autosuggestItems } = this.state;
		return (
			<div className="editableField">
				<h2>{t("Filters.city")}</h2>
				<div>
					{url === "/filters/city" ? (
						<Fragment>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadCities(value).then(cities => this.setState({ autosuggestItems: cities }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) => {
									this.setState({ autosuggestValue: suggestion.title });
									this.setCitySearchParameter(suggestion);
								}}
								getSuggestionValue={city => city.title}
								renderSuggestion={city => city.title}
								inputProps={{
									placeholder: t("Filters.city_placeholder"),
									value: autosuggestValue,
									onChange: (event, { newValue }) => this.setState({ autosuggestValue: newValue }),
									onBlur: () => autosuggestValue === "" && this.setCitySearchParameter(undefined),
									onKeyDown: e =>
										e.key === "Enter" &&
										autosuggestValue === "" &&
										this.setCitySearchParameter(undefined),
									maxLength: 32
								}}
							/>
							<Link
								to={getUrl("/filters", { city: cityFilter ? cityFilter.id : undefined })}
								role="button"
								className="back"
							>
								{t("back")}
							</Link>
							<Link
								to={getUrl("/filters/city", { city: undefined })}
								role="button"
								className="reset"
								onClick={() => this.setState({ autosuggestValue: "" })}
							>
								{t("reset")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<p>{autosuggestValue || t("Filters.city_empty")}</p>
							<Link to={getUrl("/filters/city")} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	getContentTypeSearchParameterFor = filterContentType =>
		this.contentTypeSearchParameterHas(filterContentType)
			? this.getContentTypeSearchParameterWithout(filterContentType)
			: this.getContentTypeSearchParameterWith(filterContentType);

	contentTypeSearchParameterHas = contentType =>
		this.props.contentTypesSearchParam !== undefined &&
		this.props.contentTypesSearchParam.split("-").includes(contentType);

	getContentTypeSearchParameterWith = contentType =>
		this.props.contentTypesSearchParam === undefined
			? contentType
			: this.props.contentTypesSearchParam.split("-").concat(contentType).sort().join("-");

	getContentTypeSearchParameterWithout = contentType =>
		this.props.contentTypesSearchParam === undefined || this.props.contentTypesSearchParam === contentType
			? undefined
			: this.props.contentTypesSearchParam
					.split("-")
					.filter(item => item !== contentType)
					.join("-");

	renderContent = () => {
		const { t, getUrl, url, contentTypesSearchParam, filterContentTypes } = this.props;
		const sanitizedFilterContentTypes = (contentTypesSearchParam || "")
			.split("-")
			.filter(value => filterContentTypes.includes(value));

		return (
			<div className="editableField">
				<h2>{t("Filters.content")}</h2>
				{url === "/filters/content" ? (
					<div>
						<ul>
							{filterContentTypes.map(filterContentType => (
								<li key={filterContentType}>
									<Link
										to={getUrl("/filters/content", {
											cts: this.getContentTypeSearchParameterFor(filterContentType)
										})}
										aria-selected={this.contentTypeSearchParameterHas(filterContentType)}
										replace
										className={`switch${
											this.contentTypeSearchParameterHas(filterContentType) ? " active" : ""
										}`}
									>
										{t("Filters.content_options." + filterContentType)}
									</Link>
								</li>
							))}
						</ul>
						<Link to={getUrl("/filters", { cts: contentTypesSearchParam })} role="button" className="back">
							{t("back")}
						</Link>
						<Link to={getUrl("/filters/content", { cts: undefined })} role="button" className="reset">
							{t("reset")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{sanitizedFilterContentTypes.length
								? sanitizedFilterContentTypes.map(key => t("Filters.content_options." + key)).join(", ")
								: t("Filters.content_empty")}
						</p>
						<Link to={getUrl("/filters/content")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	getTagsSearchParameterFor = tag =>
		this.tagsSearchParameterHas(tag)
			? this.getTagsSearchParameterWithout(tag)
			: this.getTagsSearchParameterWith(tag);

	tagsSearchParameterHas = tag =>
		this.props.tagsSearchParam !== undefined &&
		this.props.tagsSearchParam
			.split("-")
			.map(id => Number(id))
			.includes(tag.id);

	getTagsSearchParameterWith = tag =>
		this.props.tagsSearchParam === undefined
			? tag.id
			: this.props.tagsSearchParam.split("-").concat(tag.id).sort().join("-");

	getTagsSearchParameterWithout = tag =>
		this.props.tagsSearchParam === undefined || this.props.tagsSearchParam === tag.id.toString()
			? undefined
			: this.props.tagsSearchParam
					.split("-")
					.filter(id => Number(id) !== tag.id)
					.join("-");

	renderTags = () => {
		const { t, getUrl, url, tagsSearchParam, tags } = this.props;
		const sanitizedFilterTags = (tagsSearchParam || "")
			.split("-")
			.sort()
			.map(id => tags.find(tag => tag.id === Number(id)))
			.filter(tag => tag !== undefined);

		return (
			<div className="editableField">
				<h2>{t("Filters.tags")}</h2>
				{url === "/filters/tags" ? (
					<div>
						<ul>
							{tags.map(tag => (
								<li key={tag.id}>
									<Link
										to={getUrl("/filters/tags", {
											tags: this.getTagsSearchParameterFor(tag)
										})}
										aria-selected={this.tagsSearchParameterHas(tag)}
										replace
										className={`switch${this.tagsSearchParameterHas(tag) ? " active" : ""}`}
									>
										{tag.title}
									</Link>
								</li>
							))}
						</ul>
						<Link to={getUrl("/filters", { tags: tagsSearchParam })} role="button" className="back">
							{t("back")}
						</Link>
						<Link to={getUrl("/filters/tags", { tags: undefined })} role="button" className="reset">
							{t("reset")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{sanitizedFilterTags.length
								? sanitizedFilterTags.map(tag => tag.title).join(", ")
								: t("Filters.tags_empty")}
						</p>
						<Link to={getUrl("/filters/tags")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	renderSummary = () => (
		<p className="summary small-text">
			{this.props.t.map("Filters.summary", this.props.resultsSize, {
				_RESULT_: <strong>{this.props.resultsSize}</strong>
			})}
		</p>
	);

	render = () => (
		<Fragment>
			{this.renderCity()}
			{this.renderContent()}
			{this.renderTags()}
			{this.renderSummary()}
		</Fragment>
	);
}
