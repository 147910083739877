import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { userPropTypes, userTeaserPropTypes } from "common/user.js";
import { termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import NodeTopMeta from "common/NodeTopMeta.js";
import { websitePropTypes } from "common/Website.js";
import OrganisationReference, { organisationReferencePropTypes } from "common/OrganisationReference.js";
import ReportNodeButton from "common/ReportNodeButton.js";
import NodePublishingMeta from "common/NodePublishingMeta.js";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "common/utils.js";

export const usefulInformationPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["useful_information"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	body: PropTypes.string.isRequired,
	summary: PropTypes.string.isRequired,
	miniSummary: PropTypes.string.isRequired,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	tags: PropTypes.arrayOf(termPropTypes),
	website: websitePropTypes,
	organisations: organisationReferencePropTypes
});

export const usefulInformationStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["useful_information"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	tags: PropTypes.arrayOf(termPropTypes)
});

export default class UsefulInformation extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: usefulInformationPropTypes
	};

	render() {
		const { t, getUrl, user, node } = this.props;
		return (
			<Fragment>
				<NodeTopMeta t={t} getUrl={getUrl} node={node} />
				<ClickableDrupalContent content={node.body} />
				<Meta t={t} item={node} />
				<OrganisationReference t={t} getUrl={getUrl} organisations={node.organisations} type="full" />
				<ReportNodeButton t={t} user={user} node={node} />
			</Fragment>
		);
	}
}

export class UsefulInformationTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: usefulInformationStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<NodePublishingMeta t={t} node={node} />
				<div className="content">
					<span className="no-image"></span>
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
