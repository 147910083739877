import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { userPropTypes, userTeaserPropTypes } from "common/user.js";
import Image, { imagePropTypes } from "common/Image.js";
import Languages from "common/Languages.js";
import { termPropTypes } from "common/Term.js";
import Form, { formPropTypes } from "nodes/Form.js";
import Meta from "common/Meta.js";
import NodeTopMeta from "common/NodeTopMeta.js";
import { organisationReferencePropTypes } from "common/OrganisationReference.js";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "common/utils.js";
import NodeList, { listPropTypes } from "nodes/NodeList.js";
import ReportNodeButton from "common/ReportNodeButton.js";
import LivingCostVisualisation, { livingCostDataPropTypes } from "common/LivingCostVisualisation.js";
import SkillsAssessment from "common/SkillsAssessment.js";

export const pagePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["page"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	body: PropTypes.string,
	summary: PropTypes.string,
	miniSummary: PropTypes.string,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	tags: PropTypes.arrayOf(termPropTypes),
	organisations: organisationReferencePropTypes,
	form: formPropTypes,
	lists: PropTypes.arrayOf(listPropTypes),
	myDestinations: PropTypes.bool,
	livingCostVisualisation: PropTypes.bool,
	livingCostData: livingCostDataPropTypes,
	skillsAssessment: PropTypes.bool
});

export const pageStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["page"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	sticky: PropTypes.bool,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	tags: PropTypes.arrayOf(termPropTypes)
});

export default class Page extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: pagePropTypes,
		markNodeElement: PropTypes.element
	};

	render() {
		const { t, getUrl, user, node, markNodeElement } = this.props;
		return (
			<Fragment>
				<Languages
					t={t}
					getUrl={getUrl}
					languages={node.languages}
					language={node.language}
					alias={node.alias}
				/>
				<Image image={node.image} type="full" />
				<NodeTopMeta t={t} getUrl={getUrl} node={node} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Meta t={t} item={node} />
				{node.form && <Form t={t} form={node.form} user={user} />}
				{node.lists &&
					node.lists.length > 0 &&
					node.lists.map((list, index) => (
						<NodeList key={index} t={t} getUrl={getUrl} user={user} list={list} />
					))}
				{node.myDestinations && (
					<div className="myDestinations">
						<h2>{t("ProfilePanel.preferredHostFaculties.title")}</h2>
						{user && user.preferredHostFaculties && (
							<p>{t("ProfilePanel.preferredHostFaculties.count", user.preferredHostFaculties.length)}</p>
						)}
						{(!user || !user.preferredHostFaculties) && (
							<p>{t("ProfilePanel.preferredHostFaculties.empty")}</p>
						)}
						<Link to={getUrl("/profile/destinations")} className="button">
							{t("ProfilePanel.preferredHostFaculties.link")}
						</Link>
					</div>
				)}
				{node.livingCostVisualisation && (
					<LivingCostVisualisation t={t} getUrl={getUrl} data={node.livingCostData} />
				)}
				{node.skillsAssessment && <SkillsAssessment t={t} user={user} />}
				{markNodeElement}
				{/* ERA-350 Hide report link from pages containing forms */}
				{!node.form && <ReportNodeButton t={t} user={user} node={node} />}
			</Fragment>
		);
	}
}

export class PageTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: pageStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<div className="content">
					{node.image && <Image image={node.image} type="teaser" />}
					{!node.image && <span className="no-image"></span>}
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
