import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import history from "skeleton/history.js";
import Loading, { statusPropTypes } from "common/Loading.js";
import Ago from "common/Ago.js";
import Meta from "common/Meta.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import SlidingPanel from "common/SlidingPanel.js";
import {
	loadNotification as loadNotificationFromServer,
	readNotification,
	unreadNotification,
	handleError
} from "skeleton/DataAccess.js";
import { userPropTypes } from "common/user.js";
import "./NotificationPanel.scss";

export default class NotificationLoader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		notificationId: PropTypes.number,
		onNotificationReadChange: PropTypes.func.isRequired,
		setParentState: PropTypes.func.isRequired,
		notification: PropTypes.object,
		notificationLoadingStatus: statusPropTypes
	};

	componentDidMount() {
		this.loadNotification();
	}

	onReadNotificationClicked = () => {
		const { t, notification, onNotificationReadChange } = this.props;
		if (!notification) return;
		readNotification(notification.id)
			.then(() => {
				onNotificationReadChange();
				this.loadNotification();
			})
			.catch(error => handleError(t, error));
	};

	onUnreadNotificationClicked = () => {
		const { t, notification, onNotificationReadChange } = this.props;
		if (!notification) return;
		unreadNotification(notification.id)
			.then(() => {
				onNotificationReadChange();
				this.loadNotification();
			})
			.catch(error => handleError(t, error));
	};

	/**
	 * Loads the notification from the server.
	 */
	loadNotification = () => {
		const { t, notificationId, setParentState } = this.props;

		if (!notificationId) {
			this.props.setParentState({ notification: undefined, notificationLoadingStatus: undefined });
			return;
		}

		console.log("Loading notification data from server.");
		this.props.setParentState({ notificationLoadingStatus: "loading" });

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		loadNotificationFromServer(notificationId)
			.then(result => {
				if (requestId !== this.lastRequestId) return; // Ignore stale response.
				console.log(`XHR loaded for notification id: ${notificationId}`);
				this.props.setParentState({ notification: result, notificationLoadingStatus: undefined });
			})
			.catch(error =>
				handleError(
					t,
					error,
					() => setParentState({ notification: undefined, notificationLoadingStatus: "error-401" }),
					() => setParentState({ notification: undefined, notificationLoadingStatus: "error-404" }),
					() => setParentState({ notification: undefined, notificationLoadingStatus: "error-5xx" }),
					() => setParentState({ notification: undefined, notificationLoadingStatus: "error-network" })
				)
			);
	};

	closeLoading = () => {
		this.props.setParentState({ notificationLoadingStatus: undefined });
		history.replace(this.props.getUrl("/"));
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notificationId !== this.props.notificationId) {
			console.log(
				"Notification id changed from " + prevProps.notificationId + " to " + this.props.notificationId
			);
			this.loadNotification();
			return;
		}
	}

	render() {
		const { t, getUrl, notification, notificationLoadingStatus } = this.props;

		let content = undefined;
		if (notification) {
			content = (
				<div className="Notification">
					<Ago t={t} timestamp={notification.created} />
					<ClickableDrupalContent content={notification.body} />
					<Meta t={t} item={notification} />
					{notification.read ? (
						<button className="button" onClick={this.onUnreadNotificationClicked}>
							{t("NotificationLoader.mark.unread")}
						</button>
					) : (
						<button className="button" onClick={this.onReadNotificationClicked}>
							{t("NotificationLoader.mark.read")}
						</button>
					)}
				</div>
			);
		}

		return (
			<Fragment>
				{notificationLoadingStatus && (
					<Loading
						t={t}
						status={notificationLoadingStatus}
						retry={this.loadNotification}
						close={this.closeLoading}
					/>
				)}
				<SlidingPanel
					t={t}
					isVisible={notification !== undefined}
					title={notification ? notification.title : undefined}
					extraCssClass="NotificationPanel"
					close={getUrl("/")}
					back={true}
				>
					{content}
				</SlidingPanel>
			</Fragment>
		);
	}
}
